import React, {useState, useEffect} from 'react'
import { Modal, Button } from "react-bootstrap";
import hippo from '../static/images/hippo.png'
import harimau from '../static/images/harimau.png'
import gajah from '../static/images/gajah.png'
import kelawar from '../static/images/kelawar.png'
import pepijat from '../static/images/pepijat.png'
import pemakan from '../static/images/pemakan.png'
import paus from '../static/images/paus.png'
import gagak from '../static/images/gagak.png'
import kucing from '../static/images/kucing.png'
import memerang from '../static/images/memerang.png'
import koala from '../static/images/koala.png'
import sloth from '../static/images/sloth.png'
import penguin from '../static/images/penguin.png'
import babi from '../static/images/babi.png'
import zirafah from '../static/images/zirafah.png'
import kutu from '../static/images/kutu.png'
import amir from '../static/images/amir.png'

function JuaraHaiwan() {

    const details = {
        harimau:["Harimau Kumbang","Harimau Kumbang merupakan haiwan yang paling laju berlari diatas daratan. Kelajuannya berlari mampu mencapai 113km/j. Walaubagaimanapun, untuk jarak yang jauh, ia mungkin dikalahkan oleh haiwan lain, kerana haiwan ini cepat semput."],
        gajah:["Gajah Afrika","Gajah Afrika secara puratanya mempunyai berat hampir 6500kg. panjang dari belalai hingga keekornya adalah kira -kira 10m. Walaubagaimanapun, spesis ini telah diwartakan sebagai haiwan yang terancam dan hampir pupus oleh WHO."],
        kelawar:["Kelawar Kitti","Dengan ukuran hanya kira-kira 3cm, kelawar dari spesis Kitti dinobat sebagai haiwan mamalia(berdarah panas) yang paling kecil didunia. Kelawar ini boleh dijumpai dalam kawasan gua di Thailand dan Burma. "],
        pepijat:["Pepijat Katil","Pepijat Katil mungkin akan menghantui tidur malam anda, akan tetapi sebenarnya ia mempunyai bau yang segar, seperti berri. Mungkin, katil anda yang dirasakan wangi setelah cucian itu sebenarnya dipenuhi oleh pepijat?"],
        pemakan: ["Pemakan Semut","Pemakan Semut (Anteater) menghasilkan bauan yang busuk untuk menyelamatkan dirinya dari menjadi mangsa haiwan buas yang lain. Seperti orang – orang yang kentut untuk membuatkan orang lari 😀"],
        paus: ["Ikan Paus Biru Antartika","Ikan Paus Biru Antartika merupakan haiwan yang terbesar dilautan, malah juga didunia. Panjangnya boleh mencecah sehingga 30m dan berat bahagian lidahnya sahaja sudah sama seperti berat seekor gajah!"],
        gagak: ["Burung Gagak","Untuk kali ini, burung gagak dinobatkan sebagai haiwan paling bijak. Walaupun haiwan lain seperti anjing dan cimpanze yang kelihatan bijak kerana berinteraksi dengan manusia, burung gagak sebenarnya mampu berfikir mengenai masa hadapan, dan berkomunikasi sesama mereka dalam bahasa yang kompleks. Selain itu, haiwan ini juga mampu mencipta alatan untuk kegunaan diri sendiri, dan mengecam wajah manusia."],
        kucing: ["Kucing","Kucing sombong? mungkin ya dan mungkin tidak. Oleh kerana haiwan ini banyak menjadi peliharaan kita, banyak video – video viral yang menunjukkan ke’sombong’annya."],
        memerang: ["Memerang","Memerang tinggal dikawasan sungai secara berkumpulan, dan mereka sering berkerja gigih dalam menyediakan tempat tinggal yang selesa bagi keluarga. Empangan dibina di sekeliling sungai sebagai tempat tinggal dilengkapi dengan saliran yang baik, dan lubang – lubang yang mampu digunakan bagi mengelakkan diri daripada musuh."],
        koala: ["Koala","Koala menghabiskan masa 18-20 jam sehari, hanya untuk tidur! Disebabkan makanan kegemaran mereka yang tinggi zat dan memerlukan masa lama untuk dihadam, maka mereka lebih suka meluangkan masa untuk berjemur didahan pokok, sepanjang hidup."],
        sloth: ["Sloth","Dalam keadaan terancam, Sloth hanya mampu ber’lari’ dengan kelajuan 0.27 km/j. Bayangkan anda menghabiskan masa satu jam untuk mengelilingi padang bola sepak."],
        penguin: ["Penguin","Comel itu subjektif, dan pada min haiwan yang paling comel atas muka bumi ini adalah…Penguin! comelkan 😀"],
        babi: ["Babi","Babi adalah haiwan yang sanggup memakan apa saja yang dihidangkan kepadanya, termasuk bangkai, sampah, malah najis manusia. Eww… 🤮"],
        zirafah: ["Zirafah","Zirafah mempunyai leher yang sangat panjang, untuk memudahkannya memakan daun dari ranting pokok. Ketinggian haiwan ini mampu mencapai 6m."],
        kutu: ["Kutu","Kutu merupakan haiwan yang suka melompat. Ia juga mampu melompat sehingga 30,000 kali tanpa berhenti. Ketinggian lompatannya juga amat hebat, sekiranya disamakannya dengan manusia, ia mampu melompat setinggi bangunan Eiffel di Paris."],
        amir: ["Manusia","Terima kasih kerana anda telah berjaya membaca kesemua senarai juara- juara haiwan! Sekiranya anda mempunyai cadangan untuk mencari juara – juara bagi kategori lain, sila komen dibawah ya. Dan jangan lupa untuk kongsikan bacaan ini dengan rakan – rakan anda.❤️❤️"]
    }
    useEffect(() => {
        document.title = "Haiwan - Haiwan Juara - science4derp"
      }, []);
    

    const [show, setShow] = useState(false)
    const [modalBody, setModalBody] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = (animal) => {
        setModalTitle(details[animal][0])
        setModalBody(details[animal][1])
        setShow(true);
    };

    function myFunction() {
        var popup = document.getElementById("myPopup");
        popup.className.toggle("show");
      }

  return (
    <div className='container sectionsContainer juaraContainer'>
        <div className='row mb-3'>
            <div className='col-sm'>
                <img className="img-fluid" src={hippo} alt="Hippo"/>
            </div>
            <div className='col-sm'>
                <p className='juaraTitle'>Haiwan - Haiwan Juara</p>
                <p>Setiap hidupan di atas muka bumi ini pasti menginginkan namanya terpahat sebagai juara dalam apa jua aspek. Namun, tidak semua berpeluang untuk menjadi juara dalam kategori yang diingini. Tetapi, sekurang – kurangnya usaha yang dilakukan akan memberi kehidupan yang lebih bermakna. Jom kita lihat senarai juara – juara yang boleh dicontohi atau jauhi. Klik pada gambar untuk membaca fakta berkaitan.</p>
            </div>
        </div>
        <div className='d-flex justify-content-center'>
        <div className='row text-center mx-auto'>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={harimau} alt="harimau" onClick={() => handleShow('harimau')}/>
                <p className='juaraTxt'>Paling laju</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={gajah} alt="gajah" onClick={() => handleShow('gajah')}/>
                <p className='juaraTxt'>Paling besar didarat</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={kelawar} alt="kelawar" onClick={() => handleShow('kelawar')}/>
                <p className='juaraTxt'>Paling kecil</p>  
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={pepijat} alt="pepijat" onClick={() => handleShow('pepijat')}/>
                <p className='juaraTxt'>Paling wangi</p>
            </div>
        </div>
        </div>
        <div className='row text-center mx-auto'>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={pemakan} alt="pemakan" onClick={() => handleShow('pemakan')}/>
                <p className='juaraTxt'>Paling berbau</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={paus} alt="paus" onClick={() => handleShow('paus')}/>
                <p className='juaraTxt'>Paling besar dilaut</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={gagak} alt="gagak" onClick={() => handleShow('gagak')}/>
                <p className='juaraTxt'>Paling bijak</p>  
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={kucing} alt="kucing" onClick={() => handleShow('kucing')}/>
                <p className='juaraTxt'>Paling sombong</p>
            </div>
        </div>
        <div className='row text-center mx-auto'>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={memerang} alt="memerang" onClick={() => handleShow('memerang')}/>
                <p className='juaraTxt'>Paling rajin</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={koala} alt="koala" onClick={() => handleShow('koala')}/>
                <p className='juaraTxt'>Paling pemalas</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={sloth} alt="sloth" onClick={() => handleShow('sloth')}/>
                <p className='juaraTxt'>Paling lambat</p>  
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={penguin} alt="penguin" onClick={() => handleShow('penguin')}/>
                <p className='juaraTxt'>Paling comel</p>
            </div>
        </div>
        <div className='row text-center mx-auto'>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={babi} alt="babi" onClick={() => handleShow('babi')}/>
                <p className='juaraTxt'>Paling pelahap</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={zirafah} alt="zirafah" onClick={() => handleShow('zirafah')}/>
                <p className='juaraTxt'>Paling tinggi</p>
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={kutu} alt="kutu" onClick={() => handleShow('kutu')}/>
                <p className='juaraTxt'>Paling jauh melompat</p>  
            </div>
            <div className='col-sm border m-2 mx-auto'>
                <img className="img-fluid clickableImg" src={amir} alt="amir" onClick={() => handleShow('amir')}/>
                <p className='juaraTxt'>Paling apa??</p>
            </div>
        </div>
        <div className='popup'>
            <span className="popuptext" id="myPopup">Popup text...</span>
        </div>
    
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {modalBody}
        {modalTitle==='Burung Gagak'?<div id="emoji"><a onClick={myFunction}>🐦</a></div>:''}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Tutup
        </Button>
        </Modal.Footer>
        </Modal>
    </div>


  )
}

export default JuaraHaiwan