import React, {useEffect, useState} from 'react'
import CryptoJS from 'crypto-js';

function KodRahsia() {

  function encrypt(str) {
    const key = 'dadascfsvergheavavawqeqrwqta'; // encryption key (can be any string)

    const encrypted = CryptoJS.AES.encrypt(str, key).toString();
    
    return encrypted;
  }

  function decrypt(str) {
    const key = 'dadascfsvergheavavawqeqrwqta'; // decryption key (must match the encryption key)

    const decrypted = CryptoJS.AES.decrypt(str, key).toString(CryptoJS.enc.Utf8);
    
    return decrypted;
  }

  useEffect(() => {
    document.title = "Kod Rahsia - science4derp"
  }, []);
  
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  }

  const handleEncrypt = async (event) => {
    try {
      event.preventDefault();
      const encryptedText = await encrypt(inputText);
      setOutputText(encryptedText);
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleDecrypt = async (event) => {
    try {
      event.preventDefault();
      const decryptedText = await decrypt(inputText);
      setOutputText(decryptedText);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyOutput = (event) => {
    navigator.clipboard.writeText(outputText);
    const copyBtn = document.getElementById("copyBtn")
    copyBtn.innerHTML = "Copied"
    setTimeout(function() {
      copyBtn.textContent = 'Copy';
    }, 1000);
  }

  return (
    <div className='sectionsContainer kodRahsiaContainer'>
      <h1 className='interaktifTitle'>Kod Rahsia</h1>

      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-sm-4 m-2 p-2'>
            <div>
              <textarea placeholder="Tuliskan mesej/kod rahsia anda disini:" className="form-control txtArea" maxLength="1000" id="inputText" name="inputText" rows="7" value={inputText} onChange={handleInputChange}></textarea>
            </div>
          </div>
          <div className='col-sm-3 m-2 p-2'>
            <button className='btn kodBtn m-1' type="submit" onClick={handleEncrypt}>Encrypt</button>
            <button className='btn kodBtn m-1' type="submit" onClick={handleDecrypt}>Decrypt</button>
          </div>
          <div className='col-sm-4 m-2 p-2'>
            <div>
              <textarea placeholder="Mesej/Kod Rahsia Anda:" className="form-control txtArea" id="outputText" name="outputText" rows="5" value={outputText} disabled></textarea>
            </div>
            <button id="copyBtn" className='btn kodBtn m-1' type="button" onClick={handleCopyOutput}>Copy</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KodRahsia;
