// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBaT874uMWnWsWiIW5j0z8IIsJDl40Dsy8",
  authDomain: "science4derp-web.firebaseapp.com",
  projectId: "science4derp-web",
  storageBucket: "science4derp-web.appspot.com",
  messagingSenderId: "791655745821",
  appId: "1:791655745821:web:11bb2fdb526efbf1b639f4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export {auth, provider, db};