import {FaSwimmer, FaGamepad, FaBook, FaHamburger, FaHeadphones, FaGuitar, FaRunning, FaDumbbell, FaFutbol, FaBiking, FaMap, FaUmbrellaBeach, FaShoppingBag, FaFilm, FaPenFancy, FaPlaneDeparture, FaTv, FaMosque, FaTableTennis, FaComments, FaCode } from "react-icons/fa";

const IconMapping = {
    'FaSwimmer': <FaSwimmer title="berenang" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaGamepad': <FaGamepad title="bermain game" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaBook': <FaBook title="membaca buku" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaHamburger': <FaHamburger title="makan" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaHeadphones': <FaHeadphones title="mendengar musik" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaGuitar': <FaGuitar title="bermain gitar" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaRunning': <FaRunning title="bersenam" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaDumbbell': <FaDumbbell title="membina badan" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaFutbol': <FaFutbol title="bermain bola" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaBiking': <FaBiking title="berbasikal" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaMap': <FaMap title="mengembara" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaUmbrellaBeach': <FaUmbrellaBeach title="berkelah" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaShoppingBag': <FaShoppingBag title="membeli belah" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaFilm': <FaFilm title="menonton filem" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaPenFancy': <FaPenFancy title="menulis" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaComments': <FaComments title="berbual" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaPlaneDeparture': <FaPlaneDeparture title="melancong" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaTv': <FaTv title="menonton tv" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaMosque': <FaMosque title="beribadah" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaTableTennis': <FaTableTennis title="bermain pingpong" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>,
    'FaCode': <FaCode title="coding" size="3em" color="white" className="clickableImg mx-2 cuteCard bg-info"/>
}

export {IconMapping};