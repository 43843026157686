import React, { useState, useEffect } from "react";
import { auth, provider } from "../firebase";
import { signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";

function LogMasuk() {

    useEffect(() => {
        document.title = "LOG MASUK - science4derp"
      }, []);
    
    const navigate = useNavigate();
    const [userMail, setUserMail] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const handleSignInGoogle = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                const nameToSet = data.user.displayName || data.user.email;

                localStorage.setItem("email", data.user.email);
                localStorage.setItem("uid", data.user.uid);
                localStorage.setItem("name", nameToSet);
                console.log(data.user);
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error.code);
                console.log(error.message);
            });
    }

    const handleSignInEP = () => {
        signInWithEmailAndPassword(auth, userMail, userPassword)
            .then((userCredential) => {
                const user = userCredential.user;
                localStorage.setItem("email", user.email);
                localStorage.setItem("uid", user.uid);
                console.log(user);
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error.code);
                console.log(error.message);
            });
    }

    if (localStorage.getItem("uid")) {
        return <Navigate to="/profil" />;
    }

    return (
        <div className="container sectionsContainer loginContainer">
            <h1 className="mt-4 juaraTitle">Log Masuk</h1>
            <button className="btnLogin mt-4" onClick={handleSignInGoogle}>Log Masuk dengan Google</button>
            <p className="m-2">atau</p>
            <form>
                <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Alamat Email</label>
                    <input type="email" className="form-control mt-2" onChange={(e) => setUserMail(e.target.value)} value={userMail} placeholder="Enter email" />
                </div>
                <div className="form-group mt-4">
                    <label htmlFor="exampleInputPassword1">Kata Laluan</label>
                    <input type="password" className="form-control mt-2" onChange={(e) => setUserPassword(e.target.value)} value={userPassword} placeholder="Password" />
                </div>
                <button type="button" className="mt-4 btn btn-primary" onClick={handleSignInEP}>Log Masuk</button>
            </form>
        </div>
    );
}

export default LogMasuk;