import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';
import Rawak from './pages/interaktif/Rawak';
import Bacaan from './pages/Bacaan';
import KorekBumi from './pages/bacaan/KorekBumi';
import KeretaTerbang from './pages/bacaan/KeretaTerbang';
import JomShopping from './pages/interaktif/JomShopping';
import CheckList from './pages/interaktif/CheckList';
import KodRahsia from './pages/interaktif/KodRahsia';
import LipatanKertas from './pages/interaktif/LipatanKertas';
import RamalanAlam from './pages/interaktif/RamalanAlam';
import BadanElektrik from './pages/bacaan/BadanElektrik';
import KapalTerbang from './pages/bacaan/KapalTerbang';
import SistemSolar from './pages/bacaan/SistemSolar';
import LawanGraviti from './pages/eksperimen/LawanGraviti';
import TelurTerapung from './pages/eksperimen/TelurTerapung';
import TenggelamTimbul from './pages/eksperimen/TenggelamTimbul';
import JuaraHaiwan from './pages/JuaraHaiwan';
import ScrollToTop from './components/ScrollToTop';
import AnakMalaysia from './pages/interaktif/AnakMalaysia';
import SeniPixel from './pages/interaktif/SeniPixel';
import LogMasuk from './pages/LogMasuk';
import ProfilePage from './pages/ProfilePage';
import PenguinTidur from './pages/bacaan/PenguinTidur';
import BulanHilang from './pages/bacaan/BulanHilang';
import SampahAngkasa from './pages/bacaan/SampahAngkasa';
import FaktaAi from './pages/interaktif/PosFaktaAi'
function App() {

  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
          <Route exact path='/' element={<MainPage/>} />
          <Route exact path='/log-masuk' element={<LogMasuk/>} />
          <Route exact path='/profil' element={<ProfilePage/>} />
          <Route path='/rawak' element={<Rawak/>} />
          <Route path='/bacaan' element={<Bacaan/>} />
          <Route path='/bacaan/korek-bumi' element={<KorekBumi/>} />
          <Route path='/bacaan/kereta-terbang' element={<KeretaTerbang/>} />
          <Route path='/bacaan/kapal-terbang' element={<KapalTerbang/>} />
          <Route path='/bacaan/badan-elektrik' element={<BadanElektrik/>} />
          <Route path='/bacaan/sistem-solar' element={<SistemSolar/>} />
          <Route path='/bacaan/penguin-tidur' element={<PenguinTidur/>} />
          <Route path='/bacaan/bulan-hilang' element={<BulanHilang/>} />
          <Route path='/bacaan/sampah-angkasa' element={<SampahAngkasa/>} />
          <Route path='/jom-shopping' element={<JomShopping/>} />
          <Route path='/checklist-kehidupan' element={<CheckList/>} />
          <Route path='/kod-rahsia' element={<KodRahsia/>} />
          <Route path='/lipatan-kertas' element={<LipatanKertas/>} />
          <Route path='/ramalan-alam-semesta' element={<RamalanAlam/>} />
          <Route path='/eksperimen/lawan-graviti' element={<LawanGraviti/>} />
          <Route path='/eksperimen/telur-terapung' element={<TelurTerapung/>} />
          <Route path='/eksperimen/tenggelam-timbul' element={<TenggelamTimbul/>} />
          <Route path='/juara-haiwan' element={<JuaraHaiwan/>} />
          <Route path='/anak-malaysia' element={<AnakMalaysia/>} />
          <Route path='/seni-piksel' element={<SeniPixel/>} />
          <Route path='/fakta-ai' element={<FaktaAi/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
