import React, {useEffect} from 'react'
import mg1 from "../../static/images/mg1.png"
import mg2 from "../../static/images/mg2.png"
import mg3 from "../../static/images/mg3.png"

function LawanGraviti() {
    useEffect(() => {
    document.title = "Melawan Graviti - science4derp"
    }, []);

    return (
        <div className='eksPageContainer container juaraContainer'>
            <p className='juaraTitle'>Melawan Graviti</p>
            <div className='row mx-auto'>
                <div className='col-sm-6 border p-2'>
                    <p>Air adalah suatu elemen yang sangat unik. Disamping ianya berada disekeliling kita dalam ketiga- tiga jirim iaitu pepejal, cecair dan gas, ia juga sebenarnya mampu untuk mengalir dalam keadaan yang tidak disangkakan, iaitu melawan graviti.</p>
                    <p>Aktiviti yang bakal kita jalankan akan menunjukkan bagaimana air dapat bergerak naik keluar dari gelas dan menuju kearah gelas yang lain.</p>
                </div>
                <div className='col-sm-6 border p-2'>
                    <p>Bahan – bahan yang diperlukan:</p>
                    <ul>
                        <li>1 gelas yang berisi 3/4 air</li>
                        <li>1 gelas kosong</li>
                        <li>tisu dapur</li>
                    </ul>
                </div>
            </div>
            <p className='eksSmallTitle'>Aktiviti</p>
            <div className='row mx-auto'>
                <div className='col-sm-6 border p-2'>
                    <p>1. Gulungkan tisu dapur supaya ia menjadi seolah -olah seutas tali.</p>
                    <img className="img-fluid eksImg" src={mg1} alt="tisu"/>
                </div>
                <div className='col-sm-6 border p-2'>
                    <p>2. Masukkan satu hujung tisu kedalam gelas yang berisi air, dan satu hujung lagi kedalam gelas yang kosong.</p>
                    <img className="img-fluid eksImg" src={mg2} alt="tisu"/>
                </div>
            </div>
            <div className='row mx-auto'>
                <div className='col-sm-6 border p-2'>
                    <p>3. Perhatikan air didalam kedua – dua gelas. Apakah ianya mula seimbang?</p>
                    <img className="img-fluid eksImg" src={mg3} alt="tisu"/>
                </div>
            </div>
            <div className='mx-auto'>
                <p className='eksSmallTitle'>Kesimpulan</p>
                <p>Apakah Proses yang terjadi?</p>
                <p>Tisu yang anda letak kedalam gelas yang berisi air akan menjadi basah kerana ianya akan diresapi air secara perlahan. Kemudian, air didalam tisu tesebut akan bergerak pula kearah gelas yang kosong.</p>
                <p>Proses ini dikenali sebagai ‘tindakan kapilari’ Ianya merupakan suatu proses dimana air bergerak kearah tempat yang mempunyai daya lekatan yang lebih kuat dari tempat yang sedang ia berada.</p>
                <p>Cara yang paling mudah untuk menggambarkan situasi ini adalah sekiranya anda meletakkan tisu diatas mana – mana permukaan yang basah, anda akan dapati air bergerak secara spontan kearah tisu tersebut.</p>
                <p>Proses yang sama juga berlaku dalam tumbuhan dimana air akan bergerak dari akar ke bahagian lain walaupun ianya melawan graviti.</p>
            </div>
        </div>
    )
}

export default LawanGraviti