import React from 'react'

function Footer() {
  return (
    <footer className="ftrContainer">
    <p className="ftrText">© 2021-2024 science4derp – 003283217-A - Made with ❤️ by <a href="https://fahmijaafar.com">FJ</a></p>
    </footer>
  )
}

export default Footer