import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { collection, query, onSnapshot, where, doc, updateDoc, addDoc } from "firebase/firestore";
import { useNavigate, NavLink as Link } from "react-router-dom";
import {FaPlus, FaBriefcase, FaCode, FaGraduationCap, FaSwimmer, FaGamepad, FaBook, FaHamburger, FaHeadphones, FaGuitar, FaRunning, FaDumbbell, FaFutbol, FaBiking, FaMap, FaUmbrellaBeach, FaShoppingBag, FaFilm, FaPenFancy, FaPlaneDeparture, FaTv, FaMosque, FaTableTennis, FaComments } from "react-icons/fa";
import editImg from '../static/images/edit-image.png'
import avatarB1 from '../static/images/avatar-b1.png'
import avatarB2 from '../static/images/avatar-b2.png'
import avatarB3 from '../static/images/avatar-b3.png'
import avatarB4 from '../static/images/avatar-b4.png'
import avatarB5 from '../static/images/avatar-b5.png'
import avatarG1 from '../static/images/avatar-g1.png'
import avatarG2 from '../static/images/avatar-g2.png'
import avatarG3 from '../static/images/avatar-g3.png'
import avatarG4 from '../static/images/avatar-g4.png'
import avatarG5 from '../static/images/avatar-g5.png'
import {IconMapping} from '../components/IconMapping'

function ProfilePage() {

    const generateEmptyGrid = (numRows, numCols, base='#a75bfe') => {
        const rows = [];
        for (let i = 0; i < numRows; i++) {
           rows.push(Array.from(Array(numCols), () => base));
        }
        return rows;
       };

    const AvatarMapping = {
        "avatarB1": avatarB1,
        "avatarB2": avatarB2,
        "avatarB3": avatarB3,
        "avatarB4": avatarB4,
        "avatarB5": avatarB5,
        "avatarG1": avatarG1,
        "avatarG2": avatarG2,
        "avatarG3": avatarG3,
        "avatarG4": avatarG4,
        "avatarG5": avatarG5,
    }

    const hobbies = [
        { icon : FaSwimmer, title: "berenang", name: "FaSwimmer"},
        { icon : FaGamepad, title: "bermain game", name: "FaGamepad"},
        { icon : FaBook, title: "membaca buku", name: "FaBook"},
        { icon : FaHamburger, title: "makan", name: "FaHamburger"},
        { icon : FaHeadphones, title: "mendengar musik", name: "FaHeadphones"},
        { icon : FaGuitar, title: "bermain gitar", name: "FaGuitar"},
        { icon : FaRunning, title: "bersenam", name: "FaRunning"},
        { icon : FaDumbbell, title: "membina badan", name: "FaDumbbell"},
        { icon : FaFutbol, title: "bermain bola", name: "FaFutbol"},
        { icon : FaBiking, title: "berbasikal", name: "FaBiking"},
        { icon : FaMap, title: "mengembara", name: "FaMap"},
        { icon : FaUmbrellaBeach, title: "berkelah", name: "FaUmbrellaBeach"},
        { icon : FaShoppingBag, title: "membeli belah", name: "FaShoppingBag"},
        { icon : FaFilm, title: "menonton filem", name: "FaFilm"},
        { icon : FaPenFancy, title: "menulis", name: "FaPenFancy"},
        { icon : FaComments, title: "berbual", name: "FaComments"},
        { icon : FaPlaneDeparture, title: "melancong", name: "FaPlaneDeparture"},
        { icon : FaTv, title: "menonton tv", name: "FaTv"},
        { icon : FaMosque, title: "beribadah", name: "FaMosque"},
        { icon : FaTableTennis, title: "bermain pingpong", name: "FaTableTennis"},
        { icon : FaCode, title: "coding", name: "FaCode"},
    ]

    const HobbyIcon = ({ Icon, title, name, onClick }) => (
        <Icon
          title={title}
          size="3em"
          color="white"
          className="clickableImg mx-2 cuteCard bg-info"
          data-bs-dismiss="modal"
          onClick={() => onClick(name)}
        />
      );
    
    const navigate = useNavigate()
    const [userData, setUserData] = useState(null)
    const [status, setStatus] = useState(null)
    const [location, setLocation] = useState(null)
    const [hobbyNo, setHobbyNo] = useState(null)
    const [hobby, setHobby] = useState(['FaPlus','FaPlus','FaPlus','FaPlus','FaPlus'])
    const [avatar, setAvatar] = useState(null)
    var currentTime = new Date()
    const [day,setDay] = useState(null)
    const [month,setMonth] = useState(null)
    const [year,setYear] = useState(null)
    const [age, setAge] = useState(null)
    const [hobbyText, setHobbyText] = useState(null)
    const [pixel, setPixel] = useState(null)
    const [grid, setGrid] = useState(() => generateEmptyGrid(32, 32));

    const handleDay = (e) => {
        const inputDay = e.target.value;
        if (inputDay > 31) {
            setDay(31);
        } else {
            setDay(inputDay);
        }
        };
        
    const handleMonth = (e) => {
    const inputMonth = e.target.value;
    if (inputMonth > 12) {
        setMonth(12);
    } else {
        setMonth(inputMonth);
    }
    };
    
    const handleYear = (e) => {
    const inputYear = e.target.value;
    if (inputYear > currentTime.getFullYear()) {
        setYear(currentTime.getFullYear());
    } else {
        setYear(inputYear);
    }
    };

    const handleLocation = (e) => {
        setLocation(e.target.value);
    };
    
    useEffect(() => {
        document.title = "PROFIL - science4derp"
      }, []);

    
    useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
        if (!user) {
            localStorage.clear()
            navigate('/log-masuk');
        }
    });

    return unsubscribe;
    }, [navigate]);
    
    useEffect(() => {

        let dob = new Date(year, month - 1, day);
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
        
        setAge(Math.abs(age_dt.getUTCFullYear() - 1970));

    }, [day,month,year]);

    
    useEffect(() => {
        const titles = hobby.map((h) => {
            const foundHobby = hobbies.find((hobby) => hobby.name === h);
            return foundHobby ? foundHobby.title : null;
          });
        const joinTitles = titles.filter(n => n).join(", ")
        setHobbyText(joinTitles)
      }, [hobby]);
    
    useEffect(() => {

    var q = query(collection(db, "users"), where("uid", "==", localStorage.getItem("uid")));

    onSnapshot(q, (snapshot) =>{
        let userFBData = []
        snapshot.docs.forEach((doc) => {
            userFBData.push({ ...doc.data(), id: doc.id})
        });
        setUserData(userFBData)
        console.log(userData)
        let birthday = userFBData[0].birthday.split(".")
        setDay(birthday[0])
        setMonth(birthday[1])
        setYear(birthday[2])

        const selectedAvatar = AvatarMapping[userFBData[0].avatar] || avatarB1;
        setAvatar(selectedAvatar)

        setStatus(userFBData[0].profile_status)
        setLocation(userFBData[0].location)
        setHobby(userFBData[0].hobby)
        setPixel(userFBData[0].pixel)
        setGrid(JSON.parse(userFBData[0].pixel))
    })
    }, []);

    const updateProfile = () =>{
        const docRef = doc(db, "users", userData[0].id);
        const avatarKey = Object.keys(AvatarMapping).find(key => AvatarMapping[key] === avatar);
        updateDoc(docRef, {
            avatar: avatarKey,
            birthday: day + "." + month + "." + year,
            hobby: hobby,
            location: location,
            profile_status: status
        }).then(()=>{
            alert("Profil anda telah dikemaskinikan.")
        })
        .catch((error) => {
        console.error("Error updating the document: ", error);
        });
    }

    const addProfile = () => {
        if (avatar && hobby && location && status && day && month && year){
            const docRef = addDoc(collection(db, "users"), {
                avatar: avatar,
                birthday: day + "." + month + "." + year,
                hobby: hobby,
                location: location,
                profile_status: status,
                uid: localStorage.getItem("uid"),
                name: localStorage.getItem("name")
            });
        }
        else{
            alert("Sila lengkapkan soalan dipaparan terlebih dahulu")
        }
    }
    

    const handleHobbyChange = (hobbyName) => {
        setHobby((prevHobby) => {
            const updatedHobby = [...prevHobby];
            updatedHobby[hobbyNo] = hobbyName;
            return updatedHobby;
        });
    };

    const handleLogOut = () =>{
        auth.signOut()
        localStorage.clear()
        window.location.reload(false);
    }

    const updateLocalStorage = () =>{
        if(pixel) localStorage.setItem("pikselData",pixel)
    }

    return (
        <div className="container sectionsContainer">
            { userData && userData.length !== 0 ? <div>
            <div className="row topTable">
                <div className="col-sm-3">
                    <div className="profileImgContainer">
                    <img className="profileImage" src={avatar} alt="an avatar"/>
                    <img className="editImg" src={editImg} title="Tukar Avatar" alt="edit icon" data-bs-toggle="modal" data-bs-target="#avatarModal"/>
                    </div>
                </div>
                <div className="col-sm-9">
                    <h1 className="nameText">Profil Saya</h1>
                    <p className="introText">Hai semua. Nama saya adalah {localStorage.getItem("name")}, dan saya berumur {age} tahun. Antara hobi yang saya gemari adalah {hobbyText} dan melayari science4derp 😊. Sekarang, saya sedang {status} di {location}. Jom tengok profil saya!</p>
                </div>
            </div>
            <div className="row profileContainer">
                <div className="col-sm-4 cuteCard">
                    <label className="mb-1">Email:</label>
                    <input className="form-control mb-3 p-2" type="text" readOnly={true} value={localStorage.getItem("email")}/>
                    <label className="mb-1">Nama:</label>
                    <input className="form-control mb-3 p-2" type="text" readOnly={true} value={localStorage.getItem("name") ? localStorage.getItem("name"): ""}/>
                    <label className="mb-1">Tarikh Lahir:</label>
                    <div className='row justify-content-md-center'>
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleDay} value={day} placeholder="hari"/>
                        </div>
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleMonth} value={month} placeholder="bulan"/>
                        </div>
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleYear} value={year} placeholder="tahun"/>
                        </div>
                    </div>
                    <label className="mb-1">Saya sedang:</label>
                    <div className="flex">
                        <FaGraduationCap title="belajar" size='3em' color="white" className={status === "belajar" ? "clickableImg mx-2 cuteCard bg-info": "clickableImg mx-2 cuteCard bg-secondary"} onClick={()=>setStatus("belajar")}/>
                        <FaBriefcase title="bekerja" size='3em' color="white" className={status === "bekerja" ? "clickableImg mx-2 cuteCard bg-info": "clickableImg mx-2 cuteCard bg-secondary"} onClick={()=>setStatus("bekerja")}/>
                    </div>
                    <label className="mb-1">{status === "belajar" ? "Sekolah/Institusi" : "Organisasi/Syarikat"}:</label>
                    <input className="form-control mb-3 p-2" type="text" onChange={handleLocation} value={location}/>
                    <label className="mb-1">Hobi:</label>
                    <div className="flex">
                        {hobby.map((h, index) => (
                            <span
                            key={index}
                            onClick={() => setHobbyNo(index)}
                            data-bs-toggle="modal"
                            data-bs-target="#hobbyModal"
                            >
                            {h === 'FaPlus' ? (
                                <FaPlus
                                size="2em"
                                color="white"
                                className="clickableImg mx-2 cuteCard bg-secondary"
                                onClick={() => setHobbyNo(index)}
                                />
                            ) : (
                                IconMapping[h]
                            )}
                            </span>
                        ))}
                    </div>
                    <button className="btn btn-primary mt-2" onClick={() => updateProfile()}>Kemaskini Profil</button>
                </div>
                <div className="col-sm-7 cuteCard">
                    <h4>Hasil Seni Piksel Saya</h4>
                    {pixel?
                    <div className="cenPixel">
                    <div className="grid-container mt-2" id='divToCapture'>
                    {grid.map((row, rowIdx) => (
                    <div key={rowIdx}>
                        {row.map((col, colIdx) => (
                        <div
                            key={colIdx}
                            className='indCellPro'
                            style={{
                            backgroundColor: col
                            }}
                            >
                            </div>
                        ))}
                    </div>
                    ))}
                    </div>
                    </div>
                    :
                    <p className="mt-5 text-secondary">Tiada hasil seni direkodkan...</p>
                    }
                    <Link className="btn btn-primary mt-2" to="/seni-piksel" onClick={updateLocalStorage}>Hasilkan/Edit Seni Piksel</Link>
                </div>
            </div>
            <button className="btn btn-danger mt-5" onClick={() => handleLogOut()}>Log Keluar</button>
            <div className="modal fade" id="hobbyModal" tabIndex="-1" aria-labelledby="hobbyModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title fs-5" id="hobbyModalLabel">Hobi Saya</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {hobbies.map((hobby, index) => (
                        <HobbyIcon key={index} Icon={hobby.icon} title={hobby.title} name={hobby.name} onClick={handleHobbyChange} />
                        ))}
                    </div>
                </div>
                </div>
            </div>
            <div className="modal fade" id="avatarModal" tabIndex="-1" aria-labelledby="avatarModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title fs-5" id="avatarModalLabel">Tukar Avatar</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {[
                            avatarB1, avatarG1, avatarB2, avatarG2, avatarB3,
                            avatarG3, avatarB4, avatarG4, avatarB5, avatarG5
                        ].map((avatar, index) => (
                            <img
                            key={index}
                            className="avatarImg"
                            src={avatar}
                            alt="an avatar"
                            data-bs-dismiss="modal"
                            onClick={() => setAvatar(avatar)}
                            />
                        ))}
                        </div>
                </div>
                </div>
            </div>
            </div>:
            <div className="newbieContainer cuteCard">
                <div className="avatarContainer">
                    <p>Saya adalah seorang:</p>
                    <div className="flex">
                        <button className={avatar === "avatarB1"? "btn btn-dark mx-1" : "btn btn-light mx-1"} onClick={()=>setAvatar("avatarB1")}>lelaki</button>
                        <button className={avatar === "avatarG1"? "btn btn-dark mx-1" : "btn btn-light mx-1"} onClick={()=>setAvatar("avatarG1")}>perempuan</button>
                    </div>
                </div>
                <div className="bdayContainer">
                    <p className="mt-3">Saya lahir pada:</p>
                    <div className="col-sm-5 cuteCard">
                        <div className="row">
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleDay} value={day} placeholder="hari"/>
                        </div>
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleMonth} value={month} placeholder="bulan"/>
                        </div>
                        <div className='col-4 mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleYear} value={year} placeholder="tahun"/>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="mt-3">Saya sedang</p>
                    <div className="flex">
                        <button className={status === "belajar"? "btn btn-dark mx-1" : "btn btn-light mx-1"} onClick={()=>setStatus("belajar")}>belajar</button>
                        <button className={status === "bekerja"? "btn btn-dark mx-1" : "btn btn-light mx-1"} onClick={()=>setStatus("bekerja")}>bekerja</button>
                    </div>
                    <p className="mt-3">di...</p>
                    <input className="form-control mb-3 p-2 cuteCard locInput" type="text" onChange={handleLocation} value={location}/>
                </div>
                <div>
                    <button className="btn btn-primary mt-5" onClick={() => addProfile()}>Hasilkan Profil Saya</button>
                </div>
                <button className="btn btn-danger mt-5" onClick={() => handleLogOut()}>Log Keluar</button>
            </div>}
        </div>
    );
}

export default ProfilePage;