import React from 'react'
import { useState, useEffect } from 'react';

function CheckList() {

    useEffect(() => {
        document.title = "Check List Kehidupan - science4derp"
      }, []);

    const [total, setTotal] = useState(0)
    function checkboxes(){
        var checked = document.querySelectorAll('input[type="checkbox"]:checked').length;
        setTotal(checked)
    }

  return (
    <div className='checklistContainer'>
    <div className="container sectionsContainer">
        <h1 className="checklistTitle">Syukur, sehingga ke saat ini, saya telah berjaya...</h1>
        <div className="row ml-5 mr-5 mt-5 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb1" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👶 dilahirkan</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb2" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚶‍ melangkah</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb3" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🖐 bertegur sapa!</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb4" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👨‍🏫 belajar ABC</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb5" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🤗 berkawan</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb6" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚴‍ berbasikal</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb7" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">📚 baca buku</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb8" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏊‍ berenang</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb9" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🕌 g sejid</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb10" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">⚽ sepak bola</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb11" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚘 bawak kereta</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb12" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏫 mengular di sekolah</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb13" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🛫 terbang di udara</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb14" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🛥️ naik bot</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb15" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏰 bercanda dipantai</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb16" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">❄️ main salji</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb17" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚁 naik heli</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb18" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏆 menang pertandingan</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb19" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎶 pergi konsert</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb20" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏕️ sertai kem</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb21" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎢 main di taman tema</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb22" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎻 main alat muzik</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb23" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💋 dicium..🤭</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb24" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚘💥🚗 eksiden</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb25" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🗾 joli keluar negara</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb26" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎤 berkaraoke</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb27" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👩‍🏫 pergi tuisyen</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb28" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🌐 belajar bahasa indo</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb29" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💸 dapat angpau</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb30" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏫 habiskan sekolah</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb31" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💳 buat kad bank</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb32" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">⛰️ panjat gunung</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb33" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎽 main lumba lari</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb34" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🍳 belajar memasak</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb35" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🌋 tengok gunung meletus</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb36" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💕 bergewe, berkasih 🤭</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb37" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🖊️ sertai MLM</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb38" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🗑️ kena dump</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb39" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎓 grad dari universiti</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb40" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏢 dapat kerja</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb41" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">☝️ naik pangkat</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb42" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💵 belanja gewe</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb43" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">📰 viral</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb44" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🔥 dibuang kerja</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb45" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🗳️ mengundi</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb46" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🌷 menanam</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb47" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🤡 tukar kerja</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb48" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🌑 mimpi naik bulan</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb49" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏠 beli rumah</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb50" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">💍 bertunang</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb51" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👰 berkahwin</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb52" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👪 jadi seorang bapa/ibu</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb53" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🚶‍ ajar anak berjalan</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb54" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">📣 ajar anak bercakap</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb55" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🎓 layan anak grad</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb56" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🕋 tunai haji di Mekah</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb57" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🤵 saksikan perkahwinan anak</p>
            </div>
        </div>
        <div className="row ml-5 mr-5 mt-1 mb-2 border">
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb58" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">👴 jadi datuk/nenek</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb59" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🏖️ pencen</p>
            </div>
            <div className="col-md-4 col-sm-12 p-2 border cntnr">
                <input className="form-check-input chckbx" type="checkbox" id="cb60" value="" aria-label="..." onClick={checkboxes}/>
                <p className="itemTxt">🌎 mengelilingi dunia</p>
            </div>
        </div>
        <div className="row foot">
            <p className="footText">Yeay, saya telah melaksanakan <span id="tot">{total}</span>/60 item dalam senarai checklist hidup ini!</p>
        </div>
    </div>
  </div>
  )
}

export default CheckList