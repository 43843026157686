import React from 'react'
import logo from '../static/images/logo.png'
import { NavLink as Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

function Navbar() {

  return (
    <nav className="navbar navbar-expand-md fixed-top navbar-light px-5" style={{backgroundColor: '#fff200'}}>
        <Link className="navbar-brand" to="/">
          <img src={logo} className="img-fluid" alt="Logo Science4Derp"/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">LAMAN UTAMA</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/bacaan">BACAAN</Link>
            </li>
            <li className="nav-item">
                <HashLink className="nav-link" to="/#eksperimen">EKSPERIMEN</HashLink>
            </li>
            <li className="nav-item">
              <HashLink className="nav-link" to="/#interaktif">INTERAKTIF</HashLink>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/profil">PROFIL</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://shopee.com.my/shirt4derp" target="_blank">BELI</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link mobileNav" to="/log-masuk">{ localStorage.getItem("email") ? "": "DAFTAR/LOG MASUK" }</Link>
            </li>
          </ul>
        </div>
        <Link className="ml-auto nav-link desktopNav" to="/log-masuk">{ localStorage.getItem("email") ? "": "DAFTAR/LOG MASUK" }</Link>
    </nav>
  )
}

export default Navbar