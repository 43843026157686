import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

function Bacaan() {

  useEffect(() => {
    document.title = "BACAAN - science4derp"
  }, []);

  return (
    <div className='sectionsContainer text-center container'>
      <p className='interaktifTitle titleTxtLG'>Sudut Bacaan</p>
      <div className='row'>
        <div className='col-sm mt-4'>
          <Link to="/bacaan/sampah-angkasa">
            <div className="img-fluid img-thumbnail galleryImg sampahBg">
              <p className='bacaanTitleTxt'>Sampah Angkasa Raya</p>
            </div>
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6 mt-4'>
          <Link to="/bacaan/bulan-hilang">
            <div className="img-fluid img-thumbnail galleryImg bulanbg">
              <p className='bacaanTitleTxt'>Bagaimanakah Bumi tanpa Adanya Bulan?</p>
            </div>
          </Link>
        </div>
        <div className='col-sm-6 mt-4'>
          <Link to="/bacaan/penguin-tidur">
            <div className="img-fluid img-thumbnail galleryImg penguinBg">
              <p className='bacaanTitleTxt'>Haiwan ini Tidur Siang 10,000 Kali Sehari!</p>
            </div>
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-5 mt-4'>
          <Link to="/bacaan/korek-bumi">
            <div className="img-fluid img-thumbnail galleryImg bumibg">
              <p className='bacaanTitleTxt'>Sedalam Manakah Kita Mampu Mengorek Bumi?</p>
            </div>
          </Link>
        </div>
        <div className='col-sm-7 mt-4'>
          <Link to="/bacaan/kereta-terbang">
            <div className="img-fluid img-thumbnail galleryImg futurebg">
              <p className='bacaanTitleTxt'>Bilakah Kita Akan Mula Menggunakan Kereta Terbang?​</p>
            </div>
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm mt-4'>
          <Link to="/bacaan/sistem-solar">
            <div className="img-fluid img-thumbnail galleryImg solarBg">
              <p className='bacaanTitleTxt'>Sistem Solar</p>
            </div>
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-7 mt-4'>
          <Link to="/bacaan/badan-elektrik">
            <div className="img-fluid img-thumbnail galleryImg elektrikbg">
              <p className='bacaanTitleTxt'>Benarkah Badan Kita Menghasilkan Elektrik?</p>
            </div>
          </Link>
        </div>
        <div className='col-sm-5 mt-4'>
          <Link to="/bacaan/kapal-terbang">
            <div className="img-fluid img-thumbnail galleryImg planeBg">
              <p className='bacaanTitleTxt'>Bagaimana Pesawat Mampu Terbang??​</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Bacaan