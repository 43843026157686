export const OmbakPixel = [
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#f8e71c",
        "#f8e71c",
        "orange",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "orange",
        "#f8e71c",
        "#f8e71c",
        "orange",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#f8e71c",
        "#f8e71c",
        "orange",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "orange",
        "orange",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#bb8d65",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "darkblue",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#4a90e2",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "orange",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#f8e71c",
        "orange",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "orange",
        "#f8e71c",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#f8e71c",
        "orange",
        "orange",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#ffffff",
        "darkblue",
        "orange",
        "orange",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#ffffff",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "orange",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "#f8e71c",
        "orange",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#ffffff",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "darkblue",
        "#f8e71c",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#f8e71c",
        "#f8e71c",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#f8e71c",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "#4a90e2",
        "#ffffff",
        "#4a90e2"
    ],
    [
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#bb8d65",
        "#fec092",
        "#ffffff",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#ffffff",
        "darkblue",
        "#4a90e2",
        "#ffffff",
        "darkblue",
        "darkblue",
        "darkblue",
        "#4a90e2",
        "darkblue",
        "darkblue",
        "#ffffff",
        "#4a90e2",
        "#ffffff"
    ]
]
export const BananaPixel = [
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "orange",
        "#000000",
        "orange",
        "orange",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "orange",
        "#000000",
        "orange",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "#000000",
        "orange",
        "orange",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "orange",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ],
    [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff"
    ]
]

export const AngkasawanPixel = [
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#580558",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "maroon",
        "#000000",
        "maroon",
        "#000000",
        "maroon",
        "#000000",
        "maroon",
        "#000000",
        "#ffffff",
        "#9b9b9b",
        "#9b9b9b",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#000000",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "grey",
        "#9b9b9b",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "maroon",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#580558",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "#630dad",
        "#000000",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "grey",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "grey",
        "#ffffff",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "grey",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "maroon",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#ffffff",
        "grey",
        "#9b9b9b",
        "#630dad",
        "#630dad",
        "maroon",
        "maroon",
        "maroon",
        "#580558",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#ffffff",
        "#9b9b9b",
        "#9b9b9b",
        "#630dad",
        "#630dad",
        "maroon",
        "maroon",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#580558",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#580558",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#9013fe",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ed2e38",
        "#ed2e38",
        "#ed2e38",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#580558"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ed2e38",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#580558",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#580558",
        "#8b572a",
        "#c2946b",
        "#8b572a"
    ],
    [
        "#630dad",
        "#630dad",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#009639",
        "#009639",
        "#009639",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b"
    ],
    [
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "#630dad",
        "orange",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#c2946b",
        "#580558"
    ]
]

export const DuckPixel = [
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "orange",
        "#000000",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "orange",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#77b192"
    ],
    [
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#77b192"
    ],
    [
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "orange",
        "orange",
        "orange",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "orange",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "orange",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "orange",
        "#000000",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ],
    [
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192",
        "#77b192"
    ]
]

export const CityPixel = [
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "pink",
        "grey"
    ],
    [
        "#0179a8",
        "#0179a8",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "grey",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "grey",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "cornsilk",
        "cornsilk",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "grey",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "grey",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "grey",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "pink",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "blue",
        "#014782",
        "#014782",
        "#014782",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "grey",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "cornsilk",
        "cornsilk",
        "#8b572a",
        "cornsilk",
        "cornsilk"
    ],
    [
        "#0179a8",
        "#0179a8",
        "#0179a8",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#014782",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a",
        "#8b572a"
    ]
]

export const AlienPixel = [
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#d0021b",
        "#d0021b",
        "#000000",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#d0021b",
        "#d0021b",
        "#d0021b",
        "#d0021b",
        "#000000",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#4a90e2",
        "#d0021b",
        "#d0021b",
        "#d0021b",
        "#d0021b",
        "#4a90e2",
        "#000000",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#d0021b",
        "#d0021b",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#000000",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#000000",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#ffffff",
        "#ffffff",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#ffffff",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#000000",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#000000",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#f8e71c",
        "#f8e71c",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#f8e71c",
        "#f8e71c",
        "#f8e71c",
        "#f8e71c",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#f8e71c",
        "#f8e71c",
        "#f8e71c",
        "#f8e71c",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#f8e71c",
        "#f8e71c",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#000000",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#000000",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#017f40",
        "#000000",
        "#ffffff",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#ffffff",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#f5a623",
        "#f5a623",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#017f40",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#f5a623",
        "#f5a623",
        "#f5a623",
        "#f5a623",
        "#4a90e2",
        "#000000",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#f5a623",
        "#f5a623",
        "#f5a623",
        "#f5a623",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#f5a623",
        "#f5a623",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#000000",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#bd10e0",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#bd10e0",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#000000",
        "#000000",
        "#4a90e2",
        "#4a90e2",
        "#173d6a",
        "#173d6a",
        "#4a90e2",
        "#4a90e2",
        "#000000",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#000000",
        "#4a90e2",
        "#173d6a",
        "#173d6a",
        "#173d6a",
        "#173d6a",
        "#4a90e2",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#000000",
        "#173d6a",
        "#173d6a",
        "#173d6a",
        "#173d6a",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ],
    [
        "#feeca4",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#ffc330",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#000000",
        "#173d6a",
        "#173d6a",
        "#000000",
        "#fa8905",
        "#fa8905",
        "#fa8905",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521",
        "#ff6521"
    ]
]