import React, {useState, useEffect} from 'react'
import johor from '../../static/images/johor.webp'
import kedah from '../../static/images/kedah.webp'
import kelantan from '../../static/images/kelantan.webp'
import melaka from '../../static/images/melaka.webp'
import negeri9 from '../../static/images/negeri9.webp'
import pahang from '../../static/images/pahang.webp'
import penang from '../../static/images/penang.webp'
import perak from '../../static/images/perak.webp'
import perlis from '../../static/images/perlis.webp'
import sabah from '../../static/images/sabah.webp'
import sarawak from '../../static/images/sarawak.webp'
import selangor from '../../static/images/selangor.webp'
import terengganu from '../../static/images/terengganu.webp'
import wilayah from '../../static/images/wilayah.webp'
import covid19 from '../../static/images/covid19.webp'
import Fireworks from '../../components/fireworks'
import rakyat from '../../static/images/people.webp'
import kejar from '../../static/images/kejar.webp'
import awan from '../../static/images/awan.webp'
import skyscrapers from '../../static/images/skyscrapers.webp'
import selamatharimerdeka from '../../static/images/selamatharimerdeka.webp'
import sparks from '../../static/images/sparks.gif'
import rahman from '../../static/images/rahman.webp'
import razak from '../../static/images/razak.webp'
import hussain from '../../static/images/hussain.webp'
import mahathir from '../../static/images/mahathir.webp'
import abdullah from '../../static/images/abdullah.webp'
import najib from '../../static/images/najib.webp'
import muhyiddin from '../../static/images/muhyiddin.webp'
import ismail from '../../static/images/ismail.webp'
import anwar from '../../static/images/anwar.webp'

function AnakMalaysia() {

    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [result, setResult] = useState(null);
    const [people, setPeople] = useState(null);
    const [stateSelected, setState] = useState(null);
    const [stateData, setStateData] = useState(null);

    var currentTime = new Date()

    useEffect(() =>{

        document.title = "Aku Anak Malaysia - science4derp";

        const thisYearPeople = 33700000

        const yearStart = new Date(currentTime.getFullYear(), 0, 1);
        const daysPassed = Math.floor((currentTime - yearStart) / (1000 * 60 * 60 * 24));
        const peoplePerDay = daysPassed*1095;
        

        const secondsPassed = (new Date() - (currentTime.getTime() - 12 * 60 * 60 * 1000)) / 1000;
        const peoplePerSecond = Math.floor(1095/secondsPassed)

        const peopleInitial = thisYearPeople+peoplePerDay+peoplePerSecond

        setPeople(peopleInitial)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
          const previousPeople = people;
          setPeople(previousPeople + 1)
        }, 10000)
        return () => {
          clearInterval(interval)
        }
      }, [people])


    function createDiary() {
        if(day>0 && month>0 && year>0){
            var birthday = new Date(parseInt(year), parseInt(month) -1, parseInt(day));
            var merdekaDay = new Date(1957, 7, 31)
            var merdeka = "";

            const yearsSinceMerdekaDay = currentTime.getFullYear() - merdekaDay.getFullYear();

            var dayString = birthday.toDateString();
            var dayOnly = dayString.slice(0, 3);
            const fullBirthDay = dayOnly === "Sun" ? "Ahad" :
              dayOnly === "Mon" ? "Isnin" :
              dayOnly === "Tue" ? "Selasa" :
              dayOnly === "Wed" ? "Rabu" :
              dayOnly === "Thu" ? "Khamis" :
              dayOnly === "Fri" ? "Jumaat" :
              dayOnly === "Sat" ? "Sabtu" : "-";
            
            const fullMonth = month == 1 ? "Januari" :
            month == 2 ? "Februari" :
            month == 3 ? "Mac" :
            month == 4 ? "April" :
            month == 5 ? "Mei" :
            month == 6 ? "Jun" :
            month == 7 ? "Julai" :
            month == 8 ? "Ogos" :
            month == 9 ? "September" :
            month == 10 ? "Oktober" :
            month == 11 ? "November" :
            month == 12 ? "Disember" : "-";
                
            // To calculate the time difference of two dates
            var Difference_In_Time = merdekaDay.getTime() - birthday.getTime();
            
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var dey = Math.round(Math.abs(Difference_In_Days)).toLocaleString();
            merdeka = (Difference_In_Days < 0) ? `Aku dilahirkan ${dey} hari setelah Tanah Melayu mencapai kemerdekaan!` :
                        (Difference_In_Days > 0) ? `Aku dilahirkan ${dey} hari sebelum Tanah Melayu mencapai kemerdekaan.!` :
                        "Aku dilahirkan pada hari Kemerdekaan Tanah Melayu. Aku ni sememangnya Anak Malaysia!";

            const pm1_reign = new Date(1970, 7, 31)
            const pm2_reign = new Date(1976, 0, 14)
            const pm3_reign = new Date(1982, 6, 16)
            const pm4_reign = new Date(2003, 9, 30)
            const pm5_reign = new Date(2009, 3, 2)
            const pm6_reign = new Date(2018, 4, 10)
            const pm7_reign = new Date(2020, 1, 28)
            const pm8_reign = new Date(2021, 7, 20)
            const pm9_reign = new Date(2022, 10, 23)
            var howPM = 0

            if (birthday<pm1_reign){
                howPM = 9
            }else if(birthday<pm2_reign){
                howPM = 8
            }else if(birthday<pm3_reign){
                howPM = 7
            }else if(birthday<pm4_reign){
                howPM = 6
            }else if(birthday<pm5_reign){
                howPM = 5
            }else if(birthday<pm6_reign){
                howPM = 4
            }else if(birthday<pm7_reign){
                howPM = 3
            }else if(birthday<pm8_reign){
                howPM = 2
            }else if(birthday<pm9_reign){
                howPM = 1
            }else{
                howPM = 0
            }
            
            const daySinceBirth = Math.round((currentTime - birthday) / (1000 * 60 * 60 * 24)).toLocaleString();

            const battleOfMuar = new Date(1942, 0, 14)
            const daysSinceBattleOfMuar = Math.round((currentTime - battleOfMuar) / (1000 * 60 * 60 * 24)).toLocaleString();

            const firstCovid = new Date(2020, 0, 25)
            const daysSinceCovid = Math.round((currentTime - firstCovid) / (1000 * 60 * 60 * 24)).toLocaleString();

            const suluStandoff = new Date(2013, 1, 11)
            const daySinceSulu = Math.round((currentTime - suluStandoff) / (1000 * 60 * 60 * 24)).toLocaleString();

            setResult({yearSinceMerdeka:yearsSinceMerdekaDay, fullBirthDay: fullBirthDay, fullMonth: fullMonth, merdeka: merdeka, howPM: howPM, birth: daySinceBirth, muarBattle: daysSinceBattleOfMuar, covid19: daysSinceCovid, sulu: daySinceSulu})
        }else{
            alert('Please insert your birthday correctly!')
        }
      }
    
    const handleDay = (e) => {
    const inputDay = e.target.value;
    if (inputDay > 31) {
        setDay(31);
    } else {
        setDay(inputDay);
    }
    };
    
    const handleMonth = (e) => {
    const inputMonth = e.target.value;
    if (inputMonth > 12) {
        setMonth(12);
    } else {
        setMonth(inputMonth);
    }
    };
    
    const handleYear = (e) => {
    const inputYear = e.target.value;
    if (inputYear > currentTime.getFullYear()) {
        setYear(currentTime.getFullYear());
    } else {
        setYear(inputYear);
    }
    };

    const handleState = (negeri) =>{
        setState(negeri)
        switch(negeri){
            case 'Johor':
                setStateData({
                    'catch':'Negeri JDT takkan la korang tak kenal kan',
                    'pejuang':'Dato Onn Jaafar dan Mat Indera',
                    'buildingName':'The Astaka A',
                    'buildingHeight':'278.8 m'
                })
                break;
            case 'Kedah':
                setStateData({
                    'catch':'Nasi kandaq Yasmin power sini bak hang.',
                    'pejuang':'Tunku Muhammad Saad',
                    'buildingName':'G Residence Alor Setar',
                    'buildingHeight':'102 m'
                })
                break;
            case 'Kelantan':
                setStateData({
                    'catch':'Sek kito mandi lanje bey..',
                    'pejuang':'Tok Janggut',
                    'buildingName':'TROIKA Residences',
                    'buildingHeight':'148.9 m'
                })
                break;
            case 'Melaka':
                setStateData({
                    'catch':'Haha haawauuu..',
                    'pejuang':'Dol Said',
                    'buildingName':'Silverscape Residences @ Hatten City',
                    'buildingHeight':'195 m'
                })
                break;
            case 'Negeri Sembilan':
                setStateData({
                    'catch':'Daging itik salai masok lomak power ada sini.',
                    'pejuang':'Datuk Siamang Gagap',
                    'buildingName':'Mesahill Premier @ Mesahill',
                    'buildingHeight':'163.6 m'
                })
                break;
            case 'Pahang':
                setStateData({
                    'catch':'Ikan patin kat Temerloh tu paduu..',
                    'pejuang':'Tok Gajah dan Mat Kilau',
                    'buildingName':'Grand Ion Majestic',
                    'buildingHeight':'253 m'
                })
                break;
            case 'Penang':
                setStateData({
                    'catch':'Orang kata tempat kami banyak makanan sedap2..',
                    'pejuang':'',
                    'buildingName':'Komtar',
                    'buildingHeight':'248.7 m'
                })
                break;
            case 'Perak':
                setStateData({
                    'catch':'Kejor yop kejor!!',
                    'pejuang':'Sultan Abdullah dan Burhanuddin Al Helmy',
                    'buildingName':'The Venus Residence',
                    'buildingHeight':'143 m'
                })
                break;
            case 'Perlis':
                setStateData({
                    'catch':'Kami tak ramai tapi kami ada...',
                    'pejuang':'Rita Rudaini',
                    'buildingName':'K-PARC Tower',
                    'buildingHeight':'52 m'
                })
                break;
            case 'Sabah':
                setStateData({
                    'catch':'boleh bah kalau kau!',
                    'pejuang':'Mat Salleh dan Antanum,',
                    'buildingName':'Jesselton Twin Towers',
                    'buildingHeight':'192.15 m'
                })
                break;
            case 'Sarawak':
                setStateData({
                    'catch':'',
                    'pejuang':'Rentap dan Roslie Dhoby',
                    'buildingName':'Sheraton Kuching',
                    'buildingHeight':'144 m'
                })
                break;
            case 'Selangor':
                setStateData({
                    'catch':'takde apa yang menarik kat sini.. haha',
                    'pejuang':'Raja Mahadi',
                    'buildingName':'Pinnacle Tower',
                    'buildingHeight':'230 m'
                })
                break;
            case 'Terengganu':
                setStateData({
                    'catch':'tiap petang ambe makang kopok boh..',
                    'pejuang':'Haji Abdul Rahman Limbong',
                    'buildingName':'Menara MBKT',
                    'buildingHeight':'140.8 m'
                })
                break;
            case 'Wilayah Persekutuan':
                setStateData({
                    'catch':'Orang key ell la katakan..',
                    'pejuang':'Hj Abdullah Hukum',
                    'buildingName':'Merdeka 118',
                    'buildingHeight':'678.9 m'
                })
                break;
        }
    }

    return (
        <div className={!result ? 'sectionsContainer merdekaContainer' : 'sectionsContainer'}>
        <h1 className='interaktifTitle'>Aku Anak Malaysia</h1>

        <div className='container'>
            <div className='row justify-content-center align-items-center'>
                {result ? 
                <div className='mt-5 p-5 contentBox'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col calenderTop'>
                            <p className='birthDay'>{result["fullBirthDay"]}</p>
                        </div>
                    </div>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col calenderMid'>
                            <p className='birthNo'>{day}</p>
                        </div>
                    </div>
                    <div className='row justify-content-center align-items-center'>
                        <div className='col calenderBot'>
                            <p className='monthYear'>{result["fullMonth"]} {year}</p>
                        </div>
                    </div>
                    <p className='mt-5 diaryTxt'>Pelbagai peristiwa bersejarah yang dah aku lalui dan pelajari sepanjang aku hidup selama {result["birth"]} hari diatas muka bumi bertuah ini.</p>
                    <Fireworks/>
                    <p className='mt-3 diaryTxt mb-5'>{result["merdeka"]}</p>
                    <img src={rakyat} className="rounded mx-auto d-block img-fluid"/>
                    <p className='diaryTxt mb-5'>Pada hari ni, aku merupakan salah seorang daripada kira-kira {people.toLocaleString()} populasi penduduk yang tinggal di Malaysia.</p>
                    <div className='row'>
                        { result["howPM"] >= 9 ? <div className='col'><img src={rahman} className="img img-fluid pmPic" alt='rahman'/></div> : <div></div> }
                        { result["howPM"] >= 8 ? <div className='col'><img src={razak} className="img img-fluid pmPic" alt='razak'/></div> : <div></div> }
                        { result["howPM"] >= 7 ? <div className='col'><img src={hussain} className="img img-fluid pmPic" alt='hussain'/></div> : <div></div> }
                        { result["howPM"] >= 3 ? <div className='col'><img src={mahathir} className="img img-fluid pmPic" alt='mahathir'/></div> : <div></div> }
                        { result["howPM"] >= 5 ? <div className='col'><img src={abdullah} className="img img-fluid pmPic" alt='abdullah'/></div> : <div></div> }
                    </div>
                    <div className='row'>
                        { result["howPM"] >= 4 ? <div className='col'><img src={najib} className="img img-fluid pmPic" alt='najib'/></div> : <div></div> }
                        { result["howPM"] >= 2 ? <div className='col'><img src={muhyiddin} className="img img-fluid pmPic" alt='muhyiddin'/></div> : <div></div> }
                        { result["howPM"] >= 1 ? <div className='col'><img src={ismail} className="img img-fluid pmPic" alt='ismail'/></div> : <div></div> }
                        { result["howPM"] >= 0 ? <div className='col'><img src={anwar} className="img img-fluid pmPic" alt='anwar'/></div> : <div></div> }
                    </div>
                    <p className='mt-5 diaryTxt bomTop'>Perdana Menteri pun dah bertukar sebanyak {result["howPM"]} kali sejak kelahiranku. Siapa yang menjadi kegemaranku? hehe biarlah rahsia.</p>
                    <div className='bomContainer'>
                        <div id="boom"></div>
                        <div id="fuse"></div>
                        <img id="sparks" src={sparks} alt="bomb sparks animation"></img>
                    </div>
                    <p className='diaryTxt bomm mb-5'>Kali terakhir negaraku menyertai peperangan berskala besar adalah {result["muarBattle"]} hari yang lalu iaitu dalam Battle of Muar(1942). Syukur, lama dah ianya berlaku dan aku tidak lagi perlu bimbang akan keselamatan negara ini dari ancaman peperangan.</p>
                    <div className='thiefContainer'>
                        <img src={kejar} className="rounded mx-auto d-block img-fluid movingImg" alt='orang lari dikejar penjenayah'/>
                    </div>
                    <p className='diaryTxt thiefBtm'>Tapi, ancaman pengganas masih ada ya di sana sini. Contohnya, Pengganas Sulu memulakan serangan terhadap Sabah hanya pada {result["sulu"]} hari yang lalu. Namun, aku yakin pasukan keselamatan negara lebih kuat dan hebat dalam menghadapi mereka.</p>
                    <img src={covid19} className="img-fluid covidImg rotImg" alt='virus covid'/>
                    <p className='diaryTxt'>Walaubagaimanapun, tidak dilupakan, aku baru saja menghadapi beberapa sesi PKP, PKPP, PKPK, dan lu PK la sendiri pada beberapa tahun yang lepas. Aku ingat lagi, kes pertama Covid-19 mula dikesan dalam negara kira-kira {result["covid19"]} hari yang lalu.</p>
                    <p className='diaryTxt'>Inilah bendera negeri - negeri yang membentuk negaraku. Cuba tekan bendera negeri asal aku? Teka je</p>
                    <div className='row'>
                        <div className='col'><img src={johor} className="rounded mx-auto d-block img-fluid clickableImg" onClick={() => handleState('Johor')} alt='johor'/></div>
                        <div className='col'><img src={kedah} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Kedah')} alt='kedah'/></div>
                        <div className='col'><img src={kelantan} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Kelantan')} alt='kelantan'/></div>
                        <div className='col'><img src={melaka} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Melaka')} alt='melaka'/></div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col'><img src={negeri9} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Negeri Sembilan')} alt='negeri sembilan'/></div>
                        <div className='col'><img src={pahang} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Pahang')} alt='pahang'/></div>
                        <div className='col'><img src={penang} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Penang')} alt='penang'/></div>
                        <div className='col'><img src={perak} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Perak')} alt='perak'/></div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col'><img src={perlis} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Perlis')} alt='perlis'/></div>
                        <div className='col'><img src={sabah} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Sabah')} alt='sabah'/></div>
                        <div className='col'><img src={sarawak} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Sarawak')} alt='sarawak'/></div>
                        <div className='col'><img src={selangor} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Selangor')} alt='selangor'/></div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col'></div>
                        <div className='col'><img src={terengganu} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Terengganu')} alt='terengganu'/></div>
                        <div className='col'><img src={wilayah} className="rounded mx-auto d-block img-fluid clickableImg"  onClick={() => handleState('Wilayah Persekutuan')} alt='wilayah'/></div>
                        <div className='col'></div>
                    </div>
                    {stateSelected ? 
                    <div>
                        <p className='diaryTxt mt-5'>Pandai! Aku ni anak jati {stateSelected}. {stateData['catch']}</p>
                        <p className='diaryTxt'>Ramai pahlawan yang aku kenal dan hormati berasal dari negeriku ini. Idola kemerdekaan aku adalah {stateData['pejuang']} yang dahulunya lama berjuang untuk mempertahankan tanah air ku ini demi mencapai kemerdekaan.</p>
                        <img src={awan} className="rounded mx-auto d-block img-fluid awanImg" alt='awan'/>
                        <img src={skyscrapers} className="rounded mx-auto d-block img-fluid skyImg" alt='gambar bangunan'/>
                        <p className='diaryTxt mt-5'>Banyak juga pembangunan yang telah diusahakan oleh kerajaan semenjak dari dulu lagi, sehingga ke hari ini. Kalau dari segi pembangunan(literal), hari ini bangunan tertinggi di negeriku adalah {stateData['buildingName']} dengan ketinggian {stateData['buildingHeight']}. Uish, tinggi la tu kan haha</p>
                        <p className='diaryTxt'>Banyak lagi yang aku nak ceritakan tentang kehidupanku sebagai seorang rakyat Malaysia, cuma masa tak mengizinkan.</p>
                        <img src={selamatharimerdeka} className="rounded mx-auto d-block img-fluid merdekaImg" alt='rakyat sambut merdeka'/>
                        <p className='diaryTxt'>Cukuplah aku simpulkan yang aku berbangga dan bersyukur menjadi salah seorang rakyat Malaysia dan aku berharap segala kemajuan dan kemakmuran yang kita kecapi hari ini dapat terus dikekalkan, dan ditambah baik lagi.</p>
                        <p className='diaryTxt'>Selamat menyambut Hari Kemerdekaan Malaysia yang ke-{result['yearSinceMerdeka']} aku ucapkan kepada semua. Marilah kita bersama berganding bahu demi kebaikan kita bersama.</p>
                    </div>
                    :<br></br>}
                </div>
                :
                <div className='startSection'>
                    <h1 className='mt-5'>Masukkan Tarikh Lahir Anda:</h1>
                    <div className='row justify-content-md-center mt-5 bdateContainer'>
                        <div className='col mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleDay} value={day} placeholder="hari"/>
                        </div>
                        <div className='col mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleMonth} value={month} placeholder="bulan"/>
                        </div>
                        <div className='col mt-1'>
                            <input className="form-control form-control-lg" type="number" onChange={handleYear} value={year} placeholder="tahun"/>
                        </div>
                    </div>
                    <div>
                        <button onClick={createDiary} className="btn btn-lg btn-danger mt-5">Hasilkan Certot Saya!</button>
                    </div>
                </div>
                }
            </div>
        </div>
        </div>
    );
}

export default AnakMalaysia;
