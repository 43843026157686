import React, {useEffect} from 'react'
import {Link} from "react-router-dom";
import rawak from '../static/images/rawak.png'
import shopping from '../static/images/shopping.png'
import kertas from '../static/images/kertas.png'
import hidup from '../static/images/hidup.png'
import kod from '../static/images/kod.png'
import ramalan from '../static/images/ramalan.png'
import model from '../static/images/model.png'
import telur from '../static/images/telur.png'
import tenggelam from '../static/images/tenggelam.png'
import graviti from '../static/images/graviti.png'
import haiwanjuara from '../static/images/haiwanjuara.png'
import piksel from '../static/images/piksel.png'
import anak from '../static/images/anak-malaysia.png'
import postai from '../static/images/post-generator.png'

function MainPage() {

useEffect(() => {
    document.title = "LAMAN UTAMA - science4derp"
    }, []);
    
  return (
    <div>
        <div className="spaceContainer">
            <p className="bacaanTitle titleTxtLG">Sampah Angkasa Raya</p>
            <Link to="/bacaan/sampah-angkasa" className="btn btn-warning btn-lg bacaanBtn mb-4">BACA</Link>
        </div>
        <div id="interaktif" className="interaktifContainer p-3">
            <p className="interaktifTitle titleTxtLG">Laman Interaktif</p>
            <div className="row text-center">
                <div className="col-sm py-1">
                    <Link to="/fakta-ai">
                        <img src={postai} className="img-fluid rounded-4 border border-dark border-5" alt="pos ai"/>
                    </Link>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-sm py-1">
                    <Link to="/seni-piksel">
                        <img src={piksel} className="img-fluid rounded-4 border border-dark border-5" alt="lukis piksel"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/anak-malaysia">
                        <img src={anak} className="img-fluid rounded-4 border border-dark border-5" alt="anak malaysia"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/jom-shopping">
                        <img src={shopping} className="img-fluid rounded-4 border border-dark border-5" alt="jom shopping"/>
                    </Link>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-sm py-1">
                    <Link to="/checklist-kehidupan">
                        <img src={hidup} className="img-fluid rounded-4 border border-dark border-5" alt="checklkist kehidupan"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/kod-rahsia">
                        <img src={kod} className="img-fluid rounded-4 border border-dark border-5" alt="kod rahsia"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/ramalan-alam-semesta">
                        <img src={ramalan} className="img-fluid rounded-4 border border-dark border-5" alt="ramalan alam"/>
                    </Link>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-sm py-1">
                    <Link to="/juara-haiwan">
                        <img src={haiwanjuara} className="img-fluid rounded-4 border border-dark border-5" alt="haiwan juara"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/rawak">
                        <img src={rawak} className="img-fluid rounded-4 border border-dark border-5" alt="rawak"/>
                    </Link>
                </div>
                <div className="col-sm py-1">
                    <Link to="/lipatan-kertas">
                        <img src={kertas} className="img-fluid rounded-4 border border-dark border-5" alt="lipatan kertas"/>
                    </Link>
                </div>
            </div>
        </div>
        <div id="eksperimen" className="eksperimenContainer p-3">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6">
              <p className="eksperimenTitleTxt titleTxtLG">Jom Eksperimen</p>
              <p className="eksperimenTxt">interaksi dengan sains di dunia sebenar</p>
              <button type="button" className="btn btn-warning btn-lg bacaanBtn">DAPATKAN KIT EKSPERIMEN SCIENCE4DERP - AKAN DATANG</button>
            </div>
            <div className="col-sm-4">
              <img className="img-fluid" src={model} alt="model playing with slime"/>
            </div>
          </div>
          <div className="row text-center">
              <div className="col-sm py-1">
                  <Link to="/eksperimen/telur-terapung">
                      <img src={telur} className="img-fluid rounded-4 border border-dark border-5" alt="telur"/>
                  </Link>
              </div>
              <div className="col-sm py-1">
                  <Link to="/eksperimen/tenggelam-timbul">
                      <img src={tenggelam} className="img-fluid rounded-4 border border-dark border-5" alt="tenggelam"/>
                  </Link>
              </div>
              <div className="col-sm py-1">
                <Link to="/eksperimen/lawan-graviti">
                    <img src={graviti} className="img-fluid rounded-4 border border-dark border-5" alt="graviti"/>
                </Link>
            </div>
          </div>
      </div>
      </div>
  )
}

export default MainPage