import React, {useEffect} from 'react'
import tt1 from '../../static/images/tt1.png'
import tt2 from '../../static/images/tt2.png'
import tt3 from '../../static/images/tt3.png'
import tt4 from '../../static/images/tt4.png'
import telurMentah from '../../static/images/telur-mentah.png'
import garam from '../../static/images/garam.png'
import gelas from '../../static/images/gelas.png'
import air from '../../static/images/air.png'

function TelurTerapung() {
    useEffect(() => {
        document.title = "Telur Terapung - science4derp"
      }, []);

  return (
    <div className='eksPageContainer container juaraContainer'>
            <p className='juaraTitle'>Telur Terapung</p>
            <div className='row mx-auto'>
                <div className='col-sm-5 p-2 cuteCard'>
                    <p>Ambil sebiji telur didapur, dan masukkannya kedalam segelas air. Anda akan dapati telur tersebut tenggelam sepenuhnya didalam air kan?</p>
                    <p>Ini menunjukkan bahawa telur lebih tumpat daripada air. Okay, jom buat silap mata dengan membuatnya terapung!</p>
                </div>
                <div className='col-sm-5 col-sm-offset-1 cuteCard'>
                    <h3 className='text-center'>Bahan Eksperimen:</h3>
                    <div className='d-flex justify-content-between'>
                        <div className='text-center'>
                            <img className="img-fluid eksImg" src={telurMentah} alt="telur mentah"/>
                            <p>Telur Mentah</p>
                        </div>
                        <div className='text-center'>
                            <img className="img-fluid eksImg" src={air} alt="air kosong"/>
                            <p>Air Kosong</p>
                        </div>
                        <div className='text-center'>
                            <img className="img-fluid eksImg" src={gelas} alt="gelas"/>
                            <p>Gelas Lutsinar</p>
                        </div>
                        <div className='text-center'>
                            <img className="img-fluid eksImg" src={garam} alt="garam"/>
                            <p>Garam</p>
                        </div>
                    </div>
                </div>
            </div>
            <p className='eksSmallTitle'>Aktiviti</p>
            <div className='row mx-auto'>
                <div className='col-sm-5 cuteCard'>
                    <p>1. Isikan air kedalam gelas sehingga separuh penuh. Letakkan telur kedalam gelas tersebut dan perhatikan.</p>
                    <img className="img-fluid stepImg" src={tt1} alt="tisu"/>
                </div>
                <div className='col-sm-5 col-sm-offset-1 cuteCard'>
                    <p>2. Keluarkan semula telur tersebut, dan letakkan 5 sudu garam kedalam air dan kacau sehingga sebati.</p>
                    <img className="img-fluid stepImg" src={tt2} alt="tisu"/>
                </div>
            </div>
            <div className='row mx-auto'>
                <div className='col-sm-5 cuteCard'>
                    <p>3. Tambahkan air paip secara perlahan – lahan kedalam gelas sehingga hampir penuh.(pastikan air paip dan air garam tidak bercampur)</p>
                    <img className="img-fluid stepImg" src={tt3} alt="tisu"/>
                </div>
                <div className='col-sm-5 col-sm-offset-1 cuteCard'>
                    <p>4. Dengan cermat, masukkan telur kedalam gelas, dan perhatikan. Dimanakah kedudukan telur tersebut didalam gelas?</p>
                    <img className="img-fluid stepImg" src={tt4} alt="tisu"/>
                </div>
            </div>
            <p className='eksSmallTitle'>Kesimpulan</p>
            <div className='row mx-auto'>
                <div className='col-sm-8 col-sm-offset-1 cuteCard'>
                    <p>Air garam adalah lebih tumpat dari air paip. Maka ianya akan berada pada bahagian bawah sekali didalam gelas.</p>
                    <p>Sekiranya anda memasukkan telur dengan perlahan, telur itu akan bergerak turun melalui air paip kerana ianya lebih tumpat, dan akan berhenti pada larutan air garam.</p>
                    <p>Oleh itu, telur anda akan kelihatan seperti sedang terapung.</p>
                    <p>Nota kaki: pastikan anda menggunakan telur yang segar. Hal ini kerana telur yang telah tembelang atau busuk akan terapung pada permukaan air paip, disebabkan oleh udara yang telah memasuki rongga udara didalam telur, mengurangkan ketumpatannya.</p>
                </div>
            </div>
        </div>
  )
}

export default TelurTerapung