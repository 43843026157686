import React from 'react'
import { useState, useEffect } from 'react'
import kertas from '../../static/images/kertas.webp'
import hair from '../../static/images/hair.webp'
import sandcastle from '../../static/images/sandcastle.webp'
import pencil from '../../static/images/pencil.webp'
import ant from '../../static/images/ant.webp'
import kuaci from '../../static/images/kuaci.webp'
import coffeebean from '../../static/images/coffee-bean.webp'
import spaghetti from '../../static/images/spaghetti.webp'
import fiftysen from '../../static/images/50sen.webp'
import egg from '../../static/images/egg.webp'
import bagel from '../../static/images/bagel.webp'
import basketball from '../../static/images/basketball.webp'
import bowling from '../../static/images/bowling.webp'
import walk from '../../static/images/walk.webp'
import tall from '../../static/images/tall.webp'
import axia from '../../static/images/axia.webp'
import giraffe from '../../static/images/giraffe.webp'
import dinasour from '../../static/images/dinasour.webp'
import whale from '../../static/images/whale.webp'
import aero from '../../static/images/aero.webp'
import stadium from '../../static/images/stadium.webp'
import underwater from '../../static/images/underwater.webp'
import petronas from '../../static/images/petronas.webp'
import burj from '../../static/images/burj.webp'
import batu from '../../static/images/batu.webp'
import centralpark from '../../static/images/centralpark.webp'
import kinabalu from '../../static/images/kinabalu.webp'
import everest from '../../static/images/everest.webp'
import olympus from '../../static/images/olympus.webp'
import klia from '../../static/images/klia.webp'
import space from '../../static/images/space.webp'
import sungai from '../../static/images/sungai.webp'
import canyon from '../../static/images/canyon.webp'
import sarawak from '../../static/images/sarawak-map.webp'
import kk from '../../static/images/kk.webp'
import pluto from '../../static/images/pluto.webp'
import uk from '../../static/images/uk.webp'
import mars from '../../static/images/mars.webp'
import bumi from '../../static/images/bumi.webp'
import neptun from '../../static/images/neptun.webp'
import zuhal from '../../static/images/zuhal.webp'
import wallofchina from '../../static/images/wall-of-china.webp'
import moon from '../../static/images/moon.webp'

function LipatanKertas() {
    const [x, setX] = useState(0);
    const [description, setDescription] = useState('Anda mempunyai sekeping kertas yang berukuran 0.1mm tebal.')
    const [txtFact, setTxtFact] = useState('')
    const [imgMain, setImgMain] = useState(kertas)

    const lipat = () => {
      if (x !== 42) {
        setX((prevX) => prevX + 1)
      }
    };
    
    useEffect(() => {
        document.title = "Lipatan Kertas - science4derp"
    }, []);

    useEffect(() => {
        changeData()
    }, [x])
    
    const buka = () => {
      if (x !== 0) {
        setX((prevX) => prevX - 1)
      }
    };

    const changeData = () => {
        switch (x) {
            case 1:
                setDescription('Kertas anda kini berketebalan 0.2mm!');
                setTxtFact('Secara purata, dua helai rambut anda akan mempunyai ketebalan ini.');
                setImgMain(hair);
                break;
            case 2:
                setDescription('Kertas anda kini berketebalan 0.4mm!');
                setTxtFact('Sepertiga dari ketebalan sebutir pasir ditepi pantai. Pada tahun 2007, pasukan yang terdiri dari tujuh orang telah membina sebuah \'sandcastle\' yang tertinggi di dunia, dengan ketinggian 15,100 mm di Pantai Myrtle, Amerika Syarikat.');
                setImgMain(sandcastle);
                break;
            case 3:
                setDescription('Kertas anda kini berketebalan 0.8mm!');
                setTxtFact('Ketebalan mata pensel. Ianya diperbuat antaranya dari \'graphene\' yang merupakan karbon dalam bentuk ternipis, dengan hanya berketebalan 0.3nm.');
                setImgMain(pencil);
                break;
            case 4:
                setDescription('Kertas anda kini berketebalan 1.6mm!');
                setTxtFact('Ketebalan spageti. Makanan ini yang asalnya dari Italy kini amat digemari rakyat Malaysia. Yang mana satu menjadi pilihan anda, \'bolognese\', \'aglio olio\' atau \'carbonara\'?');
                setImgMain(spaghetti);
                break;
            case 5:
                setDescription('Kertas anda kini berketebalan 3.2mm!');
                setTxtFact('Purata bagi saiz semut api. Gigitan semut api lebih berbisa dari semut hitam kerana gigitannya melepaskan sengatan bertoksik yang dikenali sebagai \'solenopsin\'');
                setImgMain(ant);
                break;
            case 6:
                setDescription('Kertas anda kini berketebalan 6.4mm!');
                setTxtFact('Ketebalan biji kuaci. Tahukah anda kuaci berasal dari bunga matahari, dan apa kaitannya dengan penyakit \'trypophobia\'? Jangan google sekiranya anda seorang yang lemah semangat.');
                setImgMain(kuaci);
                break;
            case 7:
                setDescription('Kertas anda kini berketebalan 12.8mm!');
                setTxtFact('Purata panjang sebiji kopi. Tahukah anda, air kegemaran admin adalah air kopi. Sekiranya anda teringin untuk belanja admin secawan kopi, sila klik pada link dibawah. Terima kasih <3');
                setImgMain(coffeebean);
                break;
            case 8:
                setDescription('Kertas anda kini berketebalan 25.6mm!');
                setTxtFact('Ianya kini lebih kurang saiz duit syiling 50sen suatu masa dahulu.');
                setImgMain(fiftysen);
                break;
            case 9:
                setDescription('Kertas anda kini berketebalan 51.2mm!');
                setTxtFact('Tinggi telur ayam. Secara puratanya, telur memerlukan 21 hari eraman untuk menetas menjadi seekeor anak ayam.');
                setImgMain(egg);
                break;
            case 10:
                setDescription('Kertas anda kini berketebalan 10.2cm!');
                setTxtFact('Diameter sebiji bagel. Tahukah anda apa itu bagel? Ianya berbentuk seperti donut, cuma saiznya lebih besar.');
                setImgMain(bagel);
                break;
            case 11:
                setDescription('Kertas anda kini berketebalan 20.5cm!');
                setTxtFact('Ianya bersamaan dengan ketinggian bola keranjang. Michael Jordan merupakan atlet bola keranjang yang terhebat di dunia setakat ini, dengan purata 30 poin bagi setiap perlawanan yang disertainya. Manakala Michael Jackson pula pernah terkenal dengan gerakan moonwalk semasa hayatnya.');
                setImgMain(basketball);
                break;
            case 12:
                setDescription('Kertas anda kini berketebalan 41cm!');
                setTxtFact('Tinggi pin bowling. Markah penuh bagi permainan ini adalah 300, sekiranya anda berjaya menjatuhkan semua pin dengan setiap balingan.');
                setImgMain(bowling);
                break;
            case 13:
                setDescription('Kertas anda kini berketebalan 81.9cm!');
                setTxtFact('Purata bagi panjang langkah kaki seorang lelaki, menurut kajian oleh Pusat Sains Kesihatan Universiti Oklahoma. Rata-rata orang berjalan dengan kelajuan sekitar 1.3 m/s. Bagaimana dengan anda?');
                setImgMain(walk);
                break;
            case 14:
                setDescription('Kertas anda kini berketebalan 1.6m!');
                setTxtFact('Ketinggian purata bagi seorang lelaki di Malaysia, terapi purata bagi wanita di dunia. :3');
                setImgMain(tall);
                break;
            case 15:
                setDescription('Kertas anda kini berketebalan 3.3m!');
                setTxtFact('Ianya bersamaan panjang sebuah Perodua Axia.');
                setImgMain(axia);
                break;
            case 16:
                setDescription('Kertas anda kini berketebalan 6.6m!');
                setTxtFact('Bersamaan dengan tinggi seekor zirafah. Di beberapa kawasan di Afrika, manusia membunuh zirafah untuk memakan dagingnya. Sedap ke?');
                setImgMain(giraffe);
                break;
            case 17:
                setDescription('Kertas anda kini berketebalan 13.1m!');
                setTxtFact('Dinasour \'Brachiosaurus\'. Ia pernah menetap di muka bumi kita antara 155.7 juta tahun sehinga 150.8 juta tahun dahulu, semasa zaman Jurassic.');
                setImgMain(dinasour);
                break;
            case 18:
                setDescription('Kertas anda kini berketebalan 26.2m!');
                setTxtFact('Panjang ikan paus biru. Ikan ini merupakan haiwan terbesar yang kita kenali, pernah hidup di muka bumi setakat ini.');
                setImgMain(whale);
                break;
            case 19:
                setDescription('Kertas anda kini berketebalan 52.4m!');
                setTxtFact('Lebar sayap Boeing 747. Model kapal terbang ini telah pun menerbangkan lebih dari 3.5 bilion manusia. Nilai ini hampir mencapai separuh daripada keseluruhan umat manusia yang ada di muka bumi ini.');
                setImgMain(aero);
                break;
            case 20:
                setDescription('Kertas anda kini berketebalan 104.9m!');
                setTxtFact('Panjang sebuah padang bola sepak. Sukan ini mula dimainkan di China sejak 476 sebelum masihi.');
                setImgMain(stadium);
                break;
            case 21:
                setDescription('Kertas anda kini berketebalan 209.7m!');
                setTxtFact('kedalaman cahaya matahari yang biasanya boleh menembusi lautan.');
                setImgMain(underwater);
                break;
            case 22:
                setDescription('Kertas anda kini berketebalan 419.4m!');
                setTxtFact('9/10 tinggi Menara Berkembar Petronas. Bangunan yang terletak di Kuala Lumpur ini merupakan bangunan tertinggi di dunia sejak tahun 1998-2004, dan kini masih lagi memegang rekod sebagai menara berkembar tertinggi di dunia.');
                setImgMain(petronas);
                break;
            case 23:
                setDescription('Kertas anda kini berketebalan 839.9m!');
                setTxtFact('Ketinggian Burj Khalifa, struktur buatan manusia yang tertinggi pada masa ini. Menara ini juga menempatkan masjid tertinggi didunia pada ketinggian 577m, dan kolam renang tertinggi didunia pada ketinggian 285m.');
                setImgMain(burj);
                break;
            case 24:
                setDescription('Kertas anda kini berketebalan 1.7km!');
                setTxtFact('1.6km adalah bersamaan dengan 1 batu. Pada hari ini, Amerika Syarikat adalah antara beberapa negara yang masih menggunakan skala batu ataupun "miles" ini');
                setImgMain(batu);
                break;
            case 25:
                setDescription('Kertas anda kini berketebalan 3.4km!');
                setTxtFact('Panjang Central Park di New York, Amerika Syarikat. Taman ini terletak ditengah - tengah lautan binaan bangunan pencakar langit di bandaraya tersebut.');
                setImgMain(centralpark);
                break;
            case 26:
                setDescription('Kertas anda kini berketebalan 6.7km!');
                setTxtFact('Jarak perjalanan dari Timpohon Gate ke Laban Rata. Sekiranya anda mendaki Gunung Kinabalu, anda akan berehat di kawasan Laban Rata sebelum meneruskan perjalanan ke puncak.');
                setImgMain(kinabalu);
                break;
            case 27:
                setDescription('Kertas anda kini berketebalan 13.4km!');
                setTxtFact('Ianya kini lebih tinggi dari Gunung Everest. Gunung ini adalah gunung tertinggi didunia pada ketinggian 8,850km dari aras laut');
                setImgMain(everest);
                break;
            case 28:
                setDescription('Kertas anda kini berketebalan 26.8km!');
                setTxtFact('Ketinggian \'Olympus Mons\' di Planet Marikh. Gunung berapi ini menurut ahli sains merupakan gunung yang tertinggi pada planet yang terdapat dalam sistem solar kita.');
                setImgMain(olympus);
                break;
            case 29:
                setDescription('Kertas anda kini berketebalan 53.7km!');
                setTxtFact('Jarak perjalanan menaiki kereta antara Kuala Lumpur dengan Kuala Lumpur International Airport(KLIA). KLIA merupakan antara lapangan terbang yang terbesar didunia, dan yang ke-23 dunia dari segi jumlah trafik penumpang.');
                setImgMain(klia);
                break;
            case 30:
                setDescription('Kertas anda kini berketebalan 107.4km!');
                setTxtFact('Altitud dimana ruang angkasa bermula. Burung nasar Rüppell mencatatkan altitud hidupan tertinggi terbang pada ketinggian 11km dari aras laut.');
                setImgMain(space);
                break;
            case 31:
                setDescription('Kertas anda kini berketebalan 214.7km!');
                setTxtFact('kira-kira panjang Sungai Kelantan yang bermula dari Gua Musang merentasi 7 bandar termasuk Kuala Krai, Tanah Merah, Machang, Pasir Mas, Tumpat, dan Kota Bharu sehinggalah ke muaranya di Pengkalan Chepa.');
                setImgMain(sungai);
                break;
            case 32:
                setDescription('Kertas anda kini berketebalan 429.5km!');
                setTxtFact('Sekitar panjang \'The Grand Canyon\' di Amerika Syarikat. Grand Canyon ialah sebuah gaung yang amat curam dan berwarna-warni yang diukir oleh Sungai Colorado.');
                setImgMain(canyon);
                break;
            case 33:
                setDescription('Kertas anda kini berketebalan 859km!');
                setTxtFact('panjang negeri Sarawak, sekiranya diukur dari kawasan pantai.');
                setImgMain(sarawak);
                break;
            case 34:
                setDescription('Kertas anda kini berketebalan 1,718km!');
                setTxtFact('Kurang lebih jarak di antara Kuala Lumpur dengan Kota Kinabalu. Perjalanan menaiki kapal terbang ke kota di bawah bayu ini mengambil masa kira - kira dua jam tiga puluh minit.');
                setImgMain(kk);
                break;
            case 35:
                setDescription('Kertas anda kini berketebalan 3,436km!');
                setTxtFact('Lebih panjang dari diameter planet Pluto. Tahukah anda, Pluto pernah diklasifikasikan sebagai sebuah planet pada suatu masa dahulu, sebelum statusnya ditarik balik pada tahun 2006.');
                setImgMain(pluto);
                break;
            case 36:
                setDescription('Kertas anda kini berketebalan 6,872km!');
                setTxtFact('Diameter planet Marikh. Setakat ini, terdapat lapan kapal angkasa yang berfungsi di Marikh, enam di orbit dan dua lagi di daratan Marikh, yakni perayau Curiosity dan pendarat InSight.');
                setImgMain(mars);
                break;
            case 37:
                setDescription('Kertas anda kini berketebalan 13,744km!');
                setTxtFact('Jarak perjalanan dari Kuala Lumpur ke United Kingdom, menggunakan jalan darat!');
                setImgMain(uk);
                break;
            case 38:
                setDescription('Kertas anda kini berketebalan 27,488km!');
                setTxtFact('Kertas anda sudah boleh mengelilingi bumi ini.');
                setImgMain(bumi);
                break;
            case 39:
                setDescription('Kertas anda kini berketebalan 54,976km!');
                setTxtFact('Lebih panjang dari diameter planet Neptun. Ia merupakan planet yang kelapan dan paling jauh dari Matahari, yang mana satu pusingan orbit akan mengambil masa kira - kira 165 tahun.');
                setImgMain(neptun);
                break;
            case 40:
                setDescription('Kertas anda kini berketebalan 109,951km!');
                setTxtFact('Diameter planet zuhal, tanpa gelangnya. Planet Zuhal dijangka bakal kehilangan gelangnya dalam masa 100 juta tahun lagi.');
                setImgMain(zuhal);
                break;
            case 41:
                setDescription('Kertas anda kini berketebalan 219,902km!');
                setTxtFact('20 kali ganda penjang tembok besar China. Tembok yang mula dibina kurun ke-7 sebelum masihi ini amat panjang malah dapat dilihat daripada angkasa, merupakan salah satu dari \'7 Wonders of the World\'.');
                setImgMain(wallofchina);
                break;
            case 42:
                setDescription('Kertas anda kini berketebalan 439,805km!');
                setTxtFact('Tahniah! Hanya setelah 42 kali lipatan, kertas anda telah dapat mencapai ketinggian bulan dari permukaan bumi, malah lebih jauh dari itu.');
                setImgMain(moon);
                break;

            default:
                setDescription('Anda mempunyai sekeping kertas yang berketebalan 0.1mm.');
                setTxtFact('');
                setImgMain(kertas);
        }
}
  return (
    <div className='sectionsContainer kertasContainer'>
        <h1 className="text-center interaktifTitle">Lipatan Kertas</h1>
        <div className='row justify-content-center text-center'>
            <div className='col-sm-1'>
                <div id="imgContainer" className="container p-3 my-2 border border-secondary rounded">
                    <button id="btnBuka" onClick={buka} type="button" className="btn btn-danger">Buka</button>
                </div>
            </div>
            <div className='col-sm-8'>
                <div id="imgContainer" className="container p-3 my-2 border border-secondary rounded">
                <p id="counter" className="text-center"></p>
                <img src={imgMain} className="rounded mx-auto d-block img-fluid"/><br/>
                </div>
                <div id="descContainer" className="container p-2 my-2 border border-secondary rounded my-10 text-primary">
                    <p id="description" className="text-center">{description}</p>
                    <p id="txtFact" className="text-center">{txtFact}</p><br/>
                </div>
            </div>
            <div className='col-sm-1'>
            <div id="imgContainer" className="container p-3 my-2 border border-secondary rounded">
                    <button id="btnLipat" onClick={lipat} type="button" className="btn btn-success">Lipat</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LipatanKertas