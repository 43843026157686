import React, {useEffect} from 'react'
import komet from '../../static/images/komet.png'
import bumi from '../../static/images/bumi-half.png'
import hat from '../../static/images/hat.png'
import pluto from '../../static/images/pluto.png'
import voyager from '../../static/images/voyager.png'
import oort from '../../static/images/oort.png'
import island from '../../static/images/island.png'
import sea from '../../static/images/sea.png'
import stars from '../../static/images/stars.gif'
import marsRing from '../../static/images/mars-ring.png'
import clock from '../../static/images/clock.gif'
import plant from '../../static/images/plant.png'
import fossil from '../../static/images/fossil.png'
import organism from '../../static/images/organism.png'
import sun from '../../static/images/sun.gif'
import marsPretty from '../../static/images/mars-pretty.png'
import galaxyForm from '../../static/images/galaxy-form.png'
import milkdromeda from '../../static/images/milkdromeda.png'

function RamalanAlam() {
  useEffect(() => {
    document.title = "Ramalan Alam Semesta - science4derp"
  }, []);

  return (
    <div className='sectionsContainer ramalanContainer'>
      <p className='whiteFont interaktifTitle titleTxtLG'>RAMALAN ALAM SEMESTA</p>
      <p className='whiteFont'>Apakah yang akan terjadi kepada alam ini?</p>
      <p className='whiteFont'>dengan ilmu sains, kita boleh membuat beberapa ramalan akan perkara ini...</p>
      <p className='whiteFont'>Pada Tahun</p>
      <p className='whiteFont yearTxt'>2,061</p>
      <p className='whiteFont'>komet Halley akan kembali semula untuk dilihat dengan mata kasar</p>
      <img className="img-fluid py-5" style={{maxHeight: "200 px"}} src={komet} alt=""/>
      <img className="img-fluid py-5" src={bumi} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Pada Tahun</p>
      <p className='whiteFont yearTxt'>2,178</p>
      <p className='whiteFont'>akhirnya Pluto melengkapkan satu orbit mengelilingi matahari, sejak ia mula ditemui para saintis.</p>
      <img className="img-fluid" style={{maxWidth: "10%"}} src={hat} alt=""/><br/>
      <img className="img-fluid py-5" src={pluto} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Pada Tahun</p>
      <p className='whiteFont yearTxt'>2,300</p>
      <p className='whiteFont'>setelah mengembara berbilion kilometer,</p>
      <img className="img-fluid" style={{maxHeight: "200 px"}} src={voyager} alt=""/>
      <img className="img-fluid py-5" src={oort} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Voyager 1 akhirnya akan keluar dari sistem solar, menuju kearah awan Oort.</p>
      <p className='whiteFont yearTxt'>75,000</p>
      <p className='whiteFont'>Tahun Kemudian</p>
      <img className="img-fluid" style={{maxWidth: "30%"}} src={island} alt=""/><br/>
      <img className="img-fluid" src={sea} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Kepulauan baharu akan muncul pada peta dunia.</p>
      <p className='whiteFont yearTxt'>100,000</p>
      <p className='whiteFont'>Tahun Kemudian</p>
      <img className="img-fluid py-5" src={stars} style={{maxHeight: "200 px", maxWidth: "600px", opacity:0.5}} alt=""/>
      <p className='whiteFont'>Kebanyakkan dari buruj bintang yang dapat kita lihat pada hari ini akan hilang dari pandangan.</p>
      <p className='whiteFont yearTxt'>50</p>
      <p className='whiteFont'>Juta Tahun Kemudian</p>
      <p className='whiteFont'>Planet Marikh akan mempunyai gelangnya yang tersendiri.</p>
      <img className="img-fluid py-5" src={marsRing} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont yearTxt'>100</p>
      <p className='whiteFont'>Juta Tahun Kemudian</p>
      <p className='whiteFont'>Planet Zuhal pula akan kehilangan gelangnya...</p>
      <p className='whiteFont'>Selepas <span className='yearTxt'>180</span>Juta Tahun</p>
      <p className='whiteFont'>Bulan akan terus memperlahankan bumi.</p>
      <img className="img-fluid py-5" src={clock} style={{maxHeight: "200 px", maxWidth: "600px", opacity:0.5}} alt=""/>
      <p className='whiteFont'>Satu hari akan mengambil masa 25 jam. Kita akan ada lebih banyak masa untuk diluangkan.</p>
      <p className='whiteFont'>Selepas <span className='yearTxt'>600</span> Juta Tahun</p>
      <p className='whiteFont'>Gerhana matahari penuh dapat dilihat bagi kali terakhir.</p>
      <p className='whiteFont'>Oleh kerana kedudukan bulan yang semakin menjauh, gerhana penuh tidak lagi dapat terjadi.</p>
      <p className='whiteFont'>Selepas <span className='yearTxt'>800</span> Juta Tahun</p>
      <div className='row align-items-center justify-content-center'>
        <div className='col-sm-3'>
          <p className='whiteFont'>Matahari akan menjadi lebih terang,</p>
          <p className='whiteFont'>seterusnya menyebabkan proses</p>
          <p className='whiteFont'>fotosintesis tidak lagi dapat berlaku.</p>
        </div>
        <div className='col-sm-5'>
          <img className="img-fluid py-5" src={plant} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
        </div>
      </div>
      <div className='row align-items-center justify-content-center'>
        <div className='col-sm-3'>
          <img className="img-fluid py-5" src={fossil} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
        </div>
        <div className='col-sm-3'>
          <p className='whiteFont'>Tanpa tumbuhan diatas muka bumi,  tiada</p>
          <p className='whiteFont'>lagi hidupan yang mampu  bertahan.</p>
        </div>
      </div>
      <p className='whiteFont'>dan bumi akan kembali didominasi oleh organisma sel tunggal</p>
      <img className="img-fluid py-5" src={organism} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Selepas <span className='yearTxt'>1.1</span> Bilion Tahun</p>
      <p className='whiteFont'>Cahaya matahari akan menjadi 10% lebih terang.</p>
      <img className="img-fluid py-5" src={sun} style={{maxHeight: "200 px", maxWidth: "600px", opacity:0.5}} alt=""/>
      <p className='whiteFont'>Purata suhu pada permukaan bumi akan mencapai 47 °C. Dengan itu, lautan akan mengewap dan tiada lagi air pada muka bumi.</p>
      <p className='whiteFont'>Akan tetapi, kini planet Marikh pula mempunyai suhu yang ideal untuk hidupan.</p>
      <img className="img-fluid py-5" src={marsPretty} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Matahari akan menjadi lebih terang, seterusnya  menyebabkan proses fotosintesis tidak lagi dapat berlaku.</p>
      <p className='whiteFont'>Selepas <span className='yearTxt'>5</span> Bilion Tahun</p>
      <p className='whiteFont'>Galaksi Andromeda akan bertembung dengan Bima Sakti.</p>
      <img className="img-fluid py-5" src={galaxyForm} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Mungkinkah nama galaksi baru kita digelar 'Milkdromeda'?</p>
      <img className="img-fluid py-5" src={milkdromeda} style={{maxHeight: "200 px", maxWidth: "600px"}} alt=""/>
      <p className='whiteFont'>Akan terdapat lebih trilion bintang - bintang baru disekeliling kita.  Bayangkan permandangan yang dapat kita lihat pada waktu malam hari.</p>
      <p className='whiteFont'>BERSAMBUNG...</p>
    </div>
  )
}

export default RamalanAlam