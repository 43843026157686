import React, {useEffect} from 'react'
import tat from '../../static/images/tat.png'

function TenggelamTimbul() {
    useEffect(() => {
        document.title = "Tenggelam atau Timbul - science4derp"
      }, []);

    return (
    <div className='eksPageContainer container juaraContainer'>
        <p className='juaraTitle'>Tenggelam atau Timbul</p>
        <div className='row mx-auto'>
            <div className='col-sm-6 border p-2'>
                <p>Setiap objek di atas muka bumi ini mempunyai ketumpatan yang berbeza. Ketumpatan adalah berat dibahagi kepada isipadu sesuatu objek.</p>
                <p>Sebagai contoh, besi mempunyai ketumpatan yang lebih besar dibandingkan dengan kapas. Ini adalah kerana bagi saiz yang sama bagi kedua -dua objek, besi mempunyai berat yang lebih tinggi. </p>
                <p>Air juga mempunyai ketumpatannya yang tersendiri . Sekiranya sejenis objek timbul pada permukaan air, maka ianya kurang tumpat dari air dan sebaliknya.</p>
                <p>Mari kita kaji beberapa objek disekeliling kita, untuk menilai sama ada ianya lebih tumpat dari air atau kurang tumpat darinya.</p>
            </div>
            <div className='col-sm-6 border p-2'>
                <p>Bahan – bahan yang diperlukan:</p>
                <ul>
                    <li>mangkuk</li>
                    <li>air paip</li>
                    <li>pensel kayu</li>
                    <li>sudu besi</li>
                    <li>duit syling</li>
                    <li>mainan lego</li>
                    <li>bola pingpong</li>
                </ul>
                <p>Aktiviti:</p>
                <p>1.Isikan air kedalam mangkuk.</p>
                <p>2.Letakkan objek -objek lain kedalam mangkuk satu persatu, dan perhatikan sama ada ia tenggelam atau timbul.</p>
            </div>
        </div>
        <img className="img-fluid" src={tat} alt="classroom"/>
    </div>
  )
}

export default TenggelamTimbul