import React, { useState, useEffect } from 'react';
import { produce } from 'immer';
import { SketchPicker } from 'react-color';
import { FaCircle, FaDownload, FaImage, FaUpload, FaUserEdit } from "react-icons/fa";
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import html2canvas from 'html2canvas';
import { OmbakPixel, BananaPixel, AngkasawanPixel, DuckPixel, CityPixel, AlienPixel } from './PixelData'
import ombak from '../../static/images/ombak.png'
import banana from '../../static/images/banana.png'
import angkasawan from '../../static/images/angkasawan.png'
import duck from '../../static/images/duck.png'
import city from '../../static/images/city.png'
import alien from '../../static/images/alien.png'
import beliPixel from '../../static/images/beli-piksel.png'
import { db } from '../../firebase';
import { query, getDocs, collection, updateDoc, where } from "firebase/firestore";

const generateEmptyGrid = (numRows, numCols, base='#a75bfe') => {
 const rows = [];
 for (let i = 0; i < numRows; i++) {
    rows.push(Array.from(Array(numCols), () => base));
 }
 return rows;
};

const download = (filename, array) => {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(array));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const saveDivAsImage = () => {
  // get the div to capture
  let div = document.getElementById('divToCapture');

  // call the html2canvas function
  html2canvas(div).then(function(canvas) {
      // generate a png file and download it
      var imgData = canvas.toDataURL('image/png');
      var downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = 'pixel-art.png';
      downloadLink.click();
  });
}

const updateProfilePixel = async () => {
  if (!localStorage.getItem("uid")) {
    alert('Sila Log masuk untuk simpan hasil seni piksel anda!');
  } else {
    const q = query(collection(db, "users"), where("uid", "==", localStorage.getItem("uid")));
    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        updateDoc(doc.ref, {
          pixel: localStorage.getItem("pikselData"),
        }).then(() => {
          alert("Seni Piksel anda telah disimpan!");
        }).catch((error) => {
          console.error("Error updating the document: ", error);
        });
      });
    } catch (error) {
      console.error("Error getting document: ", error);
    }
  }
};


const SeniPixel = () => {

  const defaultColors = ['#ffffff','#ffffff','#ffffff','#ffffff','#ffffff','#ffffff', '#ffffff', '#ffffff']
  const [grid, setGrid] = useState(() => generateEmptyGrid(32, 32));
  const [color, setColor] = useState('red');
  const [showPicker, setShowPicker] = useState(false)
  const [showPickerBase, setShowPickerBase] = useState(false)
  const [layout, setLayout] = useState([32,32])
  const [baseColor, setBaseColor] = useState('#a75bfe')
  const [chosen, setChosen] =useState(defaultColors)
  const [upload, setUpload] = useState(false)

  useEffect(() => {
    document.title = "Lukis Seni Piksel - science4derp"
    if(localStorage.getItem("pikselData")){
      setGrid(JSON.parse(localStorage.getItem("pikselData")))
    }
    }, []);

  const uploadFile = () => {
  var fileInput = document.getElementById('fileInput');
  var file = fileInput.files[0];

  const reader = new FileReader();

  reader.onload = function (event) {
      const arrayAsString = event.target.result;
      const array = arrayAsString.split(',');
      var result = [];
      for (var i = 0; i < array.length; i += 32) {
          result.push([array[i], array[i + 1], array[i + 2], array[i + 3], array[i + 4], array[i + 5], array[i + 6], array[i + 7], array[i + 8], array[i + 9], array[i + 10],array[i + 11], array[i + 12], array[i + 13], array[i + 14], array[i + 15], array[i + 16], array[i + 17], array[i + 18], array[i + 19], array[i + 20], array[i + 21], array[i + 22], array[i + 23], array[i + 24], array[i + 25], array[i + 26], array[i + 27], array[i + 28], array[i + 29], array[i + 30], array[i + 31]] );
      }        
      setGrid(result)
  };

  reader.readAsText(file);
  }

 const handleCellClick = (row, col) => {
    const newGrid = produce(grid, gridCopy => {
      gridCopy[row][col] = color;
    });
    setGrid(newGrid);
    localStorage.setItem("pikselData",JSON.stringify(newGrid))
    console.log(localStorage.getItem("pikselData"))
 };

 const handleChangeComplete = (color) => {
    setColor(color.hex);
    setChosen((prevColors) => {
      let newColors = [...prevColors, color.hex];
      if (newColors.length > 8) {
          newColors.shift();
      }
      return newColors;
  });
 };

 const handleChangeBaseComplete = (color) =>{
    setBaseColor(color.hex)
 };

 const changeLayout = () =>{
    setGrid(generateEmptyGrid(layout[0],layout[1]))
 }

 const cancelLayoutChange = () =>{
  if(layout[0]===16){
    setLayout([32,32])
  }else{
    setLayout([16,32])
  }
 }

 const handleBaseColor = (color) =>{
    setGrid(generateEmptyGrid(layout[0], layout[1], color))
  }

  const handleBaseColorChange = () =>{
    setShowPickerBase(false)
    handleBaseColor(baseColor)
  }

 return (
    <div className="container sectionsContainer">
      <p className='pixelTitle titleTxtLG'>Lukis Seni Piksel</p>
      <div className='row'>
        <div className='col-sm-8'>
          <div className="grid-container mt-2 mx-auto" id='divToCapture'>
            {grid.map((row, rowIdx) => (
              <div key={rowIdx}>
                {row.map((col, colIdx) => (
                   <div
                      key={colIdx}
                      onClick={() => handleCellClick(rowIdx, colIdx)}
                      className='indCell'
                      style={{
                      backgroundColor: col
                      }}
                    >
                    </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className='col-sm-4 mt-2 justify-content-center text-center'>
          <div className="card">
            <ul className="list-group list-group-flush">
              <li className='list-group-item d-flex justify-content-between align-items-center'>
                Muat Turun/Naik
                <div>
                  <FaUserEdit title='Simpan ke Profil' className='clickableImg mx-2' color='fff200' size='2em' onClick={() => updateProfilePixel()}/>
                  <FaImage title='Muat turun gambar' className='clickableImg mx-2' color='fff200' size='2em' onClick={() => saveDivAsImage()}/>
                  <FaDownload title='Muat turun fail' className='clickableImg mx-2' color='fff200' size='2em' onClick={() => download("pixel-art.txt",grid)}/>
                  <FaUpload title='Muat naik fail' className='clickableImg mx-2'  color='fff200' size='2em'  onClick={() => setUpload(!upload)}/>
                </div>
              </li>
              {upload?
              <li className='list-group-item d-flex justify-content-between align-items-center'>
                <input type="file" class="form-control mx-2" id="fileInput"/>
                <button class="btn btn-outline-secondary" type="button" onClick={() => uploadFile()}>Muatnaik</button>
              </li>
              :
              <div></div>}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Warna Tapak
                <span className='clickableImg'>
                  <FaCircle color={baseColor} className='mx-2' size='2em' onClick={() => setShowPickerBase(!showPickerBase)}/>
                </span>
              </li>
              { showPickerBase ?
              <div className='center-content'>
                <li className="list-group-item">
                <SketchPicker color={baseColor} onChangeComplete={handleChangeBaseComplete}/>
                <button className='btn btn-primary m-2' onClick={() => handleBaseColorChange()}>Tukar Warna Tapak</button>
                </li>
              </div>
              :
              <div></div>}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Dimensi
                <div>
                  <span className={layout[0] === 16? "badge bg-primary rounded-pill clickableImg mx-2":"badge bg-secondary rounded-pill clickableImg mx-2"} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>setLayout([16,32])}>16X32</span>
                  <span className={layout[0] === 32? "badge bg-primary rounded-pill clickableImg mx-2":"badge bg-secondary rounded-pill clickableImg mx-2"} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>setLayout([32,32])}>32X32</span>
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Warna Piksel
                <span className='clickableImg'>
                <FaCircle color={color} className='mx-2' size='2em' onClick={() => setShowPicker(!showPicker)}/>
                </span>
              </li>
              { showPicker ?
              <div className='center-content'>
                <li className="list-group-item">
                <SketchPicker color={color} onChangeComplete={handleChangeComplete}/>
                </li>
              </div>
              :<div></div>}
              {chosen === defaultColors ?
              <div></div>
              :
              <li className="list-group-item d-flex justify-content-between align-items-center">
              {chosen.map((color, index) => (
                <FaCircle className='clickableImg' color={color} size='2em' onClick={() => setColor(color)}/>
              )).reverse()}
              </li>
              }
            </ul>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <h3>Galeri Seni Piksel</h3>
        <div className='col'>
          <div className="card galleryImg">
            <img src={ombak} className="img-fluid clickableImg" onClick={()=> setGrid(OmbakPixel)}/>
            <div className="card-body">
              <p className="card-title">Ombak 神奈川</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="card galleryImg">
            <img src={banana} className="img-fluid clickableImg" onClick={()=> setGrid(BananaPixel)}/>
            <div className="card-body">
              <p className="card-title">Banana Man</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="card galleryImg">
          <img src={angkasawan} className="img-fluid clickableImg" onClick={()=> setGrid(AngkasawanPixel)}/>
            <div className="card-body">
              <p className="card-title">Save Palestine!</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="card galleryImg">
          <img src={duck} className="img-fluid clickableImg" onClick={()=> setGrid(DuckPixel)}/>
            <div className="card-body">
              <p className="card-title">Itik Queks!</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="card galleryImg">
          <img src={city} className="img-fluid clickableImg" onClick={()=> setGrid(CityPixel)}/>
            <div className="card-body">
              <p className="card-title">Bandar Baru</p>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className="card galleryImg">
            <img src={alien} className="img-fluid clickableImg" onClick={()=> setGrid(AlienPixel)}/>
            <div className="card-body">
              <p className="card-title">Makhluk Asing</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <h3><a target='_blank' href='https://shope.ee/4ppnYwD6g7'><img src={beliPixel} className='beliPixelImg rounded-4 border border-dark border-5'/></a></h3>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Tukar Dimensi?</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Lukisan sedia ada akan hilang. Anda pasti ingin menukar dimensi?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={()=>cancelLayoutChange()}data-bs-dismiss="modal">Batal</button>
              <button type="button" className="btn btn-primary" onClick={()=>changeLayout()} data-bs-dismiss="modal">Tukar Dimensi</button>
            </div>
          </div>
        </div>
      </div>
    </div>
 );
};

export default SeniPixel;