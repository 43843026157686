import React from 'react'
import { useState, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap";
import alam from '../../static/images/alam.png'
import apartment from '../../static/images/apartment.png'
import banglo from '../../static/images/banglo.png'
import boba from '../../static/images/boba.png'
import durian from '../../static/images/durian.png'
import ferrari from '../../static/images/ferrari.png'
import gambar from '../../static/images/gambar.png'
import iphone from '../../static/images/iphone.png'
import jam from '../../static/images/jam.png'
import jdt from '../../static/images/jdt.png'
import kapal from '../../static/images/kapal.png'
import koko from '../../static/images/koko.png'
import lego from '../../static/images/lego.png'
import mcd from '../../static/images/mcd.png'
import myvi from '../../static/images/myvi.png'
import plane from '../../static/images/plane.png'
import ps5 from '../../static/images/ps5.png'
import samyang from '../../static/images/samyang.png'
import wagyu from '../../static/images/wagyu.png'
import y15 from '../../static/images/y15.png'

function JomShopping() {

  const [firstVal,setFirstVal] = useState(1000000000)
  const [bobaC,setBobaC] = useState(0)
  const [samyangC,setSamyangC] = useState(0)
  const [myviC,setMyviC] = useState(0)
  const [apartC,setApartC] = useState(0)
  const [iphoneC,setIphoneC] = useState(0)
  const [y15C,setY15C] = useState(0)
  const [legoC,setLegoC] = useState(0)
  const [wagyuC,setWagyuC] = useState(0)
  const [kokoC,setKokoC] = useState(0)
  const [planeC,setPlaneC] = useState(0)
  const [durianC,setDurianC] = useState(0)
  const [ps5C,setPs5C] = useState(0)
  const [jamC,setJamC] = useState(0)
  const [ferrariC,setFerrariC] = useState(0)
  const [bangloC,setBangloC] = useState(0)
  const [mcdC,setMcdC] = useState(0)
  const [kapalC,setKapalC] = useState(0)
  const [alamC,setAlamC] = useState(0)
  const [jdtC,setJdtC] = useState(0)
  const [gambarC,setGambarC] = useState(0)
  const [isSticky, setIsSticky] = useState(false)
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 10000);
  };

  useEffect(() => {

    document.title = "Jom Shopping - science4derp"
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const total = document.getElementById("total");
    const sticky = total.offsetTop;
    if (window.pageYOffset >= sticky) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  function handleBobaGClick() {
    if (firstVal >= 7) {
      setFirstVal(firstVal - 7);
      setBobaC(bobaC + 1);
    }else{
      handleShow()
    }
  }

  function handleBobaRClick() {
    if (bobaC > 0) {
      setFirstVal(firstVal + 7);
      setBobaC(bobaC - 1);
    }
  }

  function handleSamyangGClick() {
    if (firstVal >= 20) {
      setFirstVal(firstVal - 20);
      setSamyangC(samyangC + 1);
    }else{
      handleShow()
    }
  }

  function handleSamyangRClick() {
    if (samyangC > 0) {
      setFirstVal(firstVal + 20);
      setSamyangC(samyangC - 1);
    }
  }

  function handleMyviGClick() {
    if (firstVal >= 50000) {
      setFirstVal(firstVal - 50000);
      setMyviC(myviC + 1);
    }else{
      handleShow()
    }
  }

  function handleMyviRClick() {
    if (myviC > 0) {
      setFirstVal(firstVal + 50000);
      setMyviC(myviC - 1);
    }
  }

  function handleApartGClick() {
    if (firstVal >= 350000) {
      setFirstVal(firstVal - 350000);
      setApartC(apartC + 1);
    }else{
      handleShow()
    }
  }

  function handleApartRClick() {
    if (apartC > 0) {
      setFirstVal(firstVal + 350000);
      setApartC(apartC - 1);
    }
  }

  function handleIPhoneGClick() {
    if (firstVal >= 7000) {
      setFirstVal(firstVal - 7000);
      setIphoneC(iphoneC + 1);
    }else{
      handleShow()
    }
  }

  function handleIPhoneRClick() {
    if (iphoneC > 0) {
      setFirstVal(firstVal + 7000);
      setIphoneC(iphoneC - 1);
    }
  }

  function handleY15GClick() {
    if (firstVal >= 11000) {
      setFirstVal(firstVal - 11000);
      setY15C(y15C + 1);
    }else{
      handleShow()
    }
  }

  function handleY15RClick() {
    if (y15C > 0) {
      setFirstVal(firstVal + 11000);
      setY15C(y15C - 1);
    }
  }

  function handleLegoGClick() {
    if (firstVal >= 1400) {
      setFirstVal(firstVal - 1400);
      setLegoC(legoC + 1);
    }else{
      handleShow()
    }
  }

  function handleLegoRClick() {
    if (legoC > 0) {
      setFirstVal(firstVal + 1400);
      setLegoC(legoC - 1);
    }
  }

  function handleWagyuGClick() {
    if (firstVal >= 500) {
      setFirstVal(firstVal - 500);
      setWagyuC(wagyuC + 1);
    }else{
      handleShow()
    }
  }

  function handleWagyuRClick() {
    if (wagyuC > 0) {
      setFirstVal(firstVal + 500);
      setWagyuC(wagyuC - 1);
    }
  }

  function handleKokoGClick() {
    if (firstVal >= 15) {
      setFirstVal(firstVal - 15);
      setKokoC(kokoC + 1);
    }else{
      handleShow()
    }
  }

  function handleKokoRClick() {
    if (kokoC > 0) {
      setFirstVal(firstVal + 15);
      setKokoC(kokoC - 1);
    }
  }

  function handlePlaneGClick() {
    if (firstVal >= 10) {
      setFirstVal(firstVal - 10);
      setPlaneC(planeC + 1);
    }else{
      handleShow()
    }
  }

  function handlePlaneRClick() {
    if (planeC > 0) {
      setFirstVal(firstVal + 10);
      setPlaneC(planeC - 1);
    }
  }

  function handleDurianGClick() {
    if (firstVal >= 200) {
      setFirstVal(firstVal - 200);
      setDurianC(durianC + 1);
    }else{
      handleShow()
    }
  }

  function handleDurianRClick() {
    if (durianC > 0) {
      setFirstVal(firstVal + 200);
      setDurianC(durianC - 1);
    }
  }

  function handlePs5GClick() {
    if (firstVal >= 2400) {
      setFirstVal(firstVal - 2400);
      setPs5C(ps5C + 1);
    }else{
      handleShow()
    }
  }

  function handlePs5RClick() {
    if (ps5C > 0) {
      setFirstVal(firstVal + 2400);
      setPs5C(ps5C - 1);
    }
  }

  function handleJamGClick() {
    if (firstVal >= 90500) {
      setFirstVal(firstVal - 90500);
      setJamC(jamC + 1);
    }else{
      handleShow()
    }
  }

  function handleJamRClick() {
    if (jamC > 0) {
      setFirstVal(firstVal + 90500);
      setJamC(jamC - 1);
    }
  }

  function handleFerrariGClick() {
    if (firstVal >= 1460000) {
      setFirstVal(firstVal - 1460000);
      setFerrariC(ferrariC + 1);
    }else{
      handleShow()
    }
  }

  function handleFerrariRClick() {
    if (ferrariC > 0) {
      setFirstVal(firstVal + 1460000);
      setFerrariC(ferrariC - 1);
    }
  }

  function handleBangloGClick() {
    if (firstVal >= 2400000) {
      setFirstVal(firstVal - 2400000);
      setBangloC(bangloC + 1);
    }else{
      handleShow()
    }
  }

  function handleBangloRClick() {
    if (bangloC > 0) {
      setFirstVal(firstVal + 2400000);
      setBangloC(bangloC - 1);
    }
  }

  function handleMcDGClick() {
    if (firstVal >= 5000000) {
      setFirstVal(firstVal - 5000000);
      setMcdC(mcdC + 1);
    }else{
      handleShow()
    }
  }

  function handleMcDRClick() {
    if (mcdC > 0) {
      setFirstVal(firstVal + 5000000);
      setMcdC(mcdC - 1);
    }
  }

  function handleKapalGClick() {
    if (firstVal >= 30000000) {
      setFirstVal(firstVal - 30000000);
      setKapalC(kapalC + 1);
    }else{
      handleShow()
    }
  }

  function handleKapalRClick() {
    if (kapalC > 0) {
      setFirstVal(firstVal + 30000000);
      setKapalC(kapalC - 1);
    }
  }

  function handleAlamGClick() {
    if (firstVal >= 226369000) {
      setFirstVal(firstVal - 226369000);
      setAlamC(alamC + 1);
    }else{
      handleShow()
    }
  }

  function handleAlamRClick() {
    if (alamC > 0) {
      setFirstVal(firstVal + 226369000);
      setAlamC(alamC - 1);
    }
  }

  function handleJDTGClick() {
    if (firstVal >= 300000000) {
      setFirstVal(firstVal - 300000000);
      setJdtC(jdtC + 1);
    }else{
      handleShow()
    }
  }

  function handleJDTRClick() {
    if (jdtC > 0) {
      setFirstVal(firstVal + 300000000);
      setJdtC(jdtC - 1);
    }
  }

  function handleGambarGClick() {
    if (firstVal >= 405524500) {
      setFirstVal(firstVal - 405524500);
      setGambarC(gambarC + 1);
    }else{
      handleShow()
    }
  }

  function handleGambarRClick() {
    if (gambarC > 0) {
      setFirstVal(firstVal + 405524500);
      setGambarC(gambarC - 1);
    }
  }

  function calculate(){
    
    document.getElementById("alamT").style.display = "none";
    document.getElementById("apartT").style.display = "none";
    document.getElementById("bangloT").style.display = "none";
    document.getElementById("bobaT").style.display = "none";
    document.getElementById("durianT").style.display = "none";
    document.getElementById("ferrariT").style.display = "none";
    document.getElementById("gambarT").style.display = "none";
    document.getElementById("iphoneT").style.display = "none";
    document.getElementById("jamT").style.display = "none";
    document.getElementById("jdtT").style.display = "none";
    document.getElementById("kapalT").style.display = "none";
    document.getElementById("kokoT").style.display = "none";
    document.getElementById("legoT").style.display = "none";
    document.getElementById("mcdT").style.display = "none";
    document.getElementById("myviT").style.display = "none";
    document.getElementById("planeT").style.display = "none";
    document.getElementById("ps5T").style.display = "none";
    document.getElementById("samyangT").style.display = "none";
    document.getElementById("wagyuT").style.display = "none";
    document.getElementById("y15T").style.display = "none";

    if(alamC>0){
        let alam = 226369000 * alamC;
        document.getElementById("alamT").innerHTML = "Tiket ke Angkasa X" + alamC + " = RM" + alam.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("alamT").style.display = "block";
    }
    if(apartC>0){
        let apart = 350000 * apartC;
        document.getElementById("apartT").innerHTML = "Pangsapuri X" + apartC + "= RM" + apart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("apartT").style.display = "block";
    }
    if(bangloC>0){
        let banglo = 2400000 * bangloC;
        document.getElementById("bangloT").innerHTML = "Banglo Mewah X" + bangloC + "= RM" + banglo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("bangloT").style.display = "block";
    }
    if(bobaC>0){
        let boba = 7 * bobaC;
        document.getElementById("bobaT").innerHTML = "Tealive Boba X" + bobaC + "= RM" + boba.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("bobaT").style.display = "block";
    }
    if(durianC>0){
        let durian = 200 * durianC;
        document.getElementById("durianT").innerHTML = "Musang King X" + durianC + "= RM" + durian.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("durianT").style.display = "block";
    }
    if(ferrariC>0){
        let ferrari = 1460000 * ferrariC;
        document.getElementById("ferrariT").innerHTML = "Kereta Ferrari X" + ferrariC + "= RM" + ferrari.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("ferrariT").style.display = "block";
    }
    if(gambarC>0){
        let gambar = 405524500 * gambarC;
        document.getElementById("gambarT").innerHTML = "Potret de L’Artiste X" + gambarC + "= RM" + gambar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("gambarT").style.display = "block";
    }
    if(iphoneC>0){
        let iphone = 7000 * iphoneC;
        document.getElementById("iphoneT").innerHTML = "iPhone 12 Pro Max X" + iphoneC + "= RM" + iphone.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("iphoneT").style.display = "block";
    }
    if(jamC>0){
        let jam = 90500 * jamC;
        document.getElementById("jamT").innerHTML = "Jam Rolex X" + jamC + "= RM" + jam.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("jamT").style.display = "block";
    }
    if(jdtC>0){
        let jdt = 300000000 * jdtC;
        document.getElementById("jdtT").innerHTML = "Kelab JDT X" + jdtC + "= RM" + jdt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("jdtT").style.display = "block";
    }
    if(kapalC>0){
        let kapal = 30000000 * kapalC;
        document.getElementById("kapalT").innerHTML = "Kapal Layar X" + kapalC + "= RM" + kapal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("kapalT").style.display = "block";
    }
    if(kokoC>0){
        let koko = 15 * kokoC;
        document.getElementById("kokoT").innerHTML = "Choco Jar X" + kokoC + "= RM" + koko.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("kokoT").style.display = "block";
    }
    if(legoC>0){
        let lego = 1400 * legoC;
        document.getElementById("legoT").innerHTML = "Lego Technic X" + legoC + "= RM" + lego.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("legoT").style.display = "block";
    }
    if(mcdC>0){
        let mcd = 5000000 * mcdC;
        document.getElementById("mcdT").innerHTML = "McDonald's X" + mcdC + "= RM" + mcd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("mcdT").style.display = "block";
    }
    if(myviC>0){
        let myvi = 50000 * myviC;
        document.getElementById("myviT").innerHTML = "Perodua Myvi X" + myviC + "= RM" + myvi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("myviT").style.display = "block";
    }
    if(planeC>0){
        let plane = 10 * planeC;
        document.getElementById("planeT").innerHTML = "Mainan Airplane X" + planeC + "= RM" + plane.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("planeT").style.display = "block";
    }
    if(ps5C>0){
        let ps5 = 2400 * ps5C;
        document.getElementById("ps5T").innerHTML = "PlayStation 5 X" + ps5C + "= RM" + ps5.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("ps5T").style.display = "block";
    }
    if(samyangC>0){
        let samyang = 20 * samyangC;
        document.getElementById("samyangT").innerHTML = "Samyang Cheese X" + samyangC + "= RM" + samyang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("samyangT").style.display = "block";
    }
    if(wagyuC>0){
        let wagyu = 500 * wagyuC;
        document.getElementById("wagyuT").innerHTML = "Steak Wagyu X" + wagyuC + "= RM" + wagyu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("wagyuT").style.display = "block";
    }
    if(y15C>0){
        let y15 = 11000 * y15C;
        document.getElementById("y15T").innerHTML = "Yamaha Y15 X" + y15C + "= RM" + y15.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        document.getElementById("y15T").style.display = "block";
    }
    var finalVal = 1000000000 - firstVal;
    document.getElementById("totalReceipt").innerHTML = "Jumlah = RM" + finalVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // if(finalVal==1000000000){
    //   document.getElementById("rayaTxt").innerHTML = "Tahniah. Anda telah membelanjakan 1 Billion ringgit sedaara.. Klu seterusnya ada pada haiwan yang mampu meramal masa depan.."
    // }
}

  return (
    <div className="container-fluid sectionsContainer shoppingContainer">
        <div className="text-center">
            <p className='interaktifTitle'>Jom Shopping</p>
            <h4>Menurut Forbes, terdapat 16 orang Billionair di Malaysia. Mereka adalah individu yang mempunyai aset bernilai lebih RM1 Billion.</h4>
            <h5> Kini, anda juga mampu berbelanja seperti mereka. Pilihan ditangan anda. Jom berbelanja dengan bijak!</h5>
        </div>
        <div id="total" className={isSticky ? "sticky" : ""}>
            <h1 id="totalPrice">RM {firstVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h1>
        </div>
        <div id="main">
            <div className="row text-center">
                <div className="col-sm mt-5">
                    <img src={boba} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Tealive Boba</p>
                    <p className="price">RM7</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleBobaRClick}>Jual</button>
                        </span>
                        <p id="bobaC" className="form-control mr-1 ml-1">{bobaC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleBobaGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={samyang} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Samyang Cheese</p>
                    <p className="price">RM20</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleSamyangRClick}>Jual</button>
                        </span>
                        <p id="samyangC" className="form-control mr-1 ml-1">{samyangC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleSamyangGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <a target="_blank" href="https://shopee.com.my/-Ready-Stock-Kapal-Terbang-Mainan-Berlampu-LED-Foam-Toys-Aeroplane-Hand-Thrown-i.4551976.8640854183">
                        <img src={plane} className="img-fluid img-thumbnail" alt="Responsive image"></img></a>
                    <p className="name">Mainan Airplane</p>
                    <p className="price">RM10</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handlePlaneRClick}>Jual</button>
                        </span>
                        <p id="planeC" className="form-control mr-1 ml-1">{planeC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handlePlaneGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={koko} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Choco Jar</p>
                    <p className="price">RM15</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleKokoRClick}>Jual</button>
                        </span>
                        <p id="kokoC" className="form-control mr-1 ml-1">{kokoC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleKokoGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={durian} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Musang King</p>
                    <p className="price">RM200</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleDurianRClick}>Jual</button>
                        </span>
                        <p id="durianC" className="form-control mr-1 ml-1">{durianC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleDurianGClick}>Beli</button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-sm mt-5">
                    <img src={wagyu} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Steak Wagyu</p>
                    <p className="price">RM500</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleWagyuRClick}>Jual</button>
                        </span>
                        <p id="wagyuC" className="form-control mr-1 ml-1">{wagyuC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleWagyuGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={lego} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Lego Technic</p>
                    <p className="price">RM1,400</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleLegoRClick}>Jual</button>
                        </span>
                        <p id="legoC" className="form-control mr-1 ml-1">{legoC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleLegoGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={ps5} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">PlayStation 5</p>
                    <p className="price">RM2,400</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handlePs5RClick}>Jual</button>
                        </span>
                        <p id="ps5C" className="form-control mr-1 ml-1">{ps5C}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handlePs5GClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={iphone} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">iPhone 12 Pro Max</p>
                    <p className="price">RM7,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleIPhoneRClick}>Jual</button>
                        </span>
                        <p id="iphoneC" className="form-control mr-1 ml-1">{iphoneC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleIPhoneGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={y15} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Yamaha Y15</p>
                    <p className="price">RM11,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleY15RClick}>Jual</button>
                        </span>
                        <p id="y15C" className="form-control mr-1 ml-1">{y15C}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleY15GClick}>Beli</button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-sm mt-5">
                    <img src={myvi} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Perodua Myvi</p>
                    <p className="price">RM50,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleMyviRClick}>Jual</button>
                        </span>
                        <p id="myviC" className="form-control mr-1 ml-1">{myviC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleMyviGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={jam} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Jam Rolex</p>
                    <p className="price">RM90,500</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleJamRClick}>Jual</button>
                        </span>
                        <p id="jamC" className="form-control mr-1 ml-1">{jamC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleJamGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={apartment} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Pangsapuri</p>
                    <p className="price">RM350,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleApartRClick}>Jual</button>
                        </span>
                        <p id="apartC" className="form-control mr-1 ml-1">{apartC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleApartGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={ferrari} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Kereta Ferrari</p>
                    <p className="price">RM1,460,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleFerrariRClick}>Jual</button>
                        </span>
                        <p id="ferrariC" className="form-control mr-1 ml-1">{ferrariC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleFerrariGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={banglo} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Banglo Mewah</p>
                    <p className="price">RM2,400,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleBangloRClick}>Jual</button>
                        </span>
                        <p id="bangloC" className="form-control mr-1 ml-1">{bangloC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleBangloGClick}>Beli</button>
                        </span>
                    </div>
                </div>
            </div>
            <div className="mb-5 row text-center">
                <div className="col-sm mt-5">
                    <img src={mcd} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">McDonalds</p>
                    <p className="price">RM5,000,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleMcDRClick}>Jual</button>
                        </span>
                        <p id="mcdC" className="form-control mr-1 ml-1">{mcdC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleMcDGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={kapal} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Kapal Layar</p>
                    <p className="price">RM30,000,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleKapalRClick}>Jual</button>
                        </span>
                        <p id="kapalC" className="form-control mr-1 ml-1">{kapalC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleKapalGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={alam} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Tiket ke Angkasa</p>
                    <p className="price">RM226,369,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleAlamRClick}>Jual</button>
                        </span>
                        <p id="alamC" className="form-control mr-1 ml-1">{alamC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleAlamGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={jdt} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Kelab JDT</p>
                    <p className="price">RM300,000,000</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleJDTRClick}>Jual</button>
                        </span>
                        <p id="jdtC" className="form-control mr-1 ml-1">{jdtC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleJDTGClick}>Beli</button>
                        </span>
                    </div>
                </div>
                <div className="col-sm mt-5">
                    <img src={gambar} className="img-fluid img-thumbnail" alt="Responsive image"></img>
                    <p className="name">Potret de L’Artiste</p>
                    <p className="price">RM405,524,500</p>
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button className="btn btn-danger" type="button" onClick={handleGambarRClick}>Jual</button>
                        </span>
                        <p id="gambarC" className="form-control mr-1 ml-1">{gambarC}</p>
                        <span className="input-group-btn">
                            <button className="btn btn-success" type="button" onClick={handleGambarGClick}>Beli</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className='calBtn'>
          <button className="btn btn-info" onClick={calculate}>Cetak Resit</button>
        </div>
        
        <div id="receipt">
            
            <p id="gambarT"></p>
            <p id="jdtT"></p>
            <p id="alamT"></p>
            <p id="kapalT"></p>
            <p id="mcdT"></p>
            <p id="bangloT"></p>
            <p id="ferrariT"></p>
            <p id="apartT"></p>
            <p id="jamT"></p>
            <p id="myviT"></p>
            <p id="y15T"></p>
            <p id="iphoneT"></p>
            <p id="ps5T"></p>
            <p id="legoT"></p>
            <p id="wagyuT"></p>
            <p id="durianT"></p>
            <p id="kokoT"></p>
            <p id="planeT"></p>
            <p id="samyangT"></p>
            <p id="bobaT"></p>
            <p id="totalReceipt"></p>
            <p id="rayaTxt"></p>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Ooppss, Tak Cukup Modai</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Mintak maaf bang, modai hang tak cukup dah. Cuba juai hat lain dulu, baru boleh beli hat ni ya.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Tutup
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  )
}

export default JomShopping