import React, {useEffect} from 'react'
import elektrik1 from '../../static/images/elektrik1.png'
import elektrik2 from '../../static/images/elektrik2.png'

function BadanElektrik() {
  useEffect(() => {
    document.title = "Benarkah Badan Kita Menghasilkan Elektrik? - science4derp"
  }, []);

  return (
    <div className="elektrikContainer sectionsContainer">
        <div className="container txtContainer mt-5 py-5">
            <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Benarkah Badan Kita Menghasilkan Elektrik?</h1>
            <p className="bodyText">Anda pasti pernah mendengar perkataan elektrik, dan terfikir betapa bahayanya sekiranya anda tersentuh arus elektrik yang kelihatan menakutkan seperti petir dilangit. Tapi, sedarkah anda bahawa tanpa elektrik, anda tidak mungkin dapat membaca artikel ini, dan ianya bukan kerana peranti yang anda gunakan untuk membaca tidak dapat berfungsi akan tetapi otak anda juga tidak dapat berfungsi tanpa elektrik!</p>
            <p className="bodyText">Setiap satu perbuatan, sama ada kita sedar atau tidak sebenarnya dikawal dan digerakkan dengan signal elektrik yang melingkari dalam tubuh badan kita. Setiap sesuatu adalah terbina dari atom, dan atom pula mengandungi proton(cas positif), neutron(cas neutral) dan elektron(cas negatif).</p>
            <img className="img-fluid" src={elektrik1} alt="Lelaki berelektrik"/>
            <p className="bodyText">Apabila cas – cas ini tidak seimbang, maka atom akan menjadi bercaj samaada positif atau negatif. Disinilah cas negatif boleh mengalir dari satu atom ke atom yang lain. Aliran cas negatif inilah yang kita panggil sebagai elektrik. Oleh disebabkan tubuh badan kita mempunyai jutaan malah berbillion atom, sudah semestinya kita juga mampu menjana elektrik.</p>
            <p className="bodyText">Baiklah, sekiranya kita boleh menjana elektrik, dimana pula terletaknya bateri yang menyimpan tenaga? Adakah ianya disimpan didalam organ, atau kulit? Sebenarnya, tenaga yang kita gunakan disimpan didalam lemak dan otot kita. Manakala proses mengecas tenaga pula datangnya dari pengambilan makanan. Sebab itulah, kita memerlukan makanan setiap hari bagi menambah tenaga. Sekiranya pengambilan makanan jauh melebihi penggunaan tenaga, akan menyebabkan kegemukkan!</p>
            <img className="img-fluid" src={elektrik2} alt="Plug Point"/>
            <p className="bodyText">Sistem saraf yang menghantar signal ke otak, degupan jantung yang berdenyut setiap saat dan pandangan mata yang dapat melihat, semuanya adalah dipengaruhi oleh aliran elektrik dalam diri kita.</p>
            <p className="bodyText">Tetapi, mengapa kita masih boleh terkena renjatan elektrik? Yang membezakan adalah kekuatan tenaga elektrik yang mengalir. Bayangkan kekuatan petir dilangit, kuasanya mampu membakar dan merosakkan sistem elektrik yang mengalir dalam tubuh badan kita. Ialah, kita bukannya mempunyai fius untuk menahan kuasa tersebut. Maka, kekal selamat dan sihat!</p>
        </div>
    </div>
  )
}

export default BadanElektrik