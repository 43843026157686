import React, { useState, useEffect } from 'react';
import Groq from "groq-sdk";
import { FaRegCopy } from "react-icons/fa";
import Malay from "../../static/images/malaysia-icon.png"
import English from "../../static/images/united-kingdom-icon.png"

function FaktaAi() {

  const groq = new Groq({ apiKey: process.env.REACT_APP_GROQ_API_KEY, dangerouslyAllowBrowser: true });

  async function generate(topic, postNo){
    let prompt;
    if (language ==="EN"){
      prompt = `List ${postNo} interesting facts about ${topic}, in less than 50 words per fact. Ensure to put the answer in a numbered list.`
    } else{
      prompt = `Dengan menggunakan Bahasa Melayu, senaraikan ${postNo} fakta menarik berkaitan ${topic}, kurang dari 50 perkataan bagi setiap fakta. Pastikan jawapan anda dalam senarai bernombor, dan setiap jawapan dalam satu baris sahaja.`
    }
    const data = await getGroqChatCompletion(prompt)
    return data
  }

  async function getGroqChatCompletion(prompt) {
    return groq.chat.completions.create({
      messages: [
        {
          role: "user",
          content: prompt,
        },
      ],
      model: "llama3-8b-8192",
    });
  }

  useEffect(() => {
    document.title = "Post Fakta AI - science4derp"

    setUserEmail(localStorage.getItem("email"))
  }, []);

  const [userEmail, setUserEmail] = useState(null)
  const [result, setResult] = useState(false)
  const [topic, setTopic] = useState('')
  const [postNo, setPostNo] = useState(1)
  const [language, setLanguage] = useState('EN')

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  }

  const handlePostNoChange = (event) => {
    const value = event.target.value
    const intValue = parseInt(value);
    if (intValue > 0 && intValue <= 10){
      setPostNo(intValue);
    }
  }

  const handleGenerate = async (event) => {
    try {
      event.preventDefault();
      const generatedPost = await generate(topic,postNo);
      const rawData = generatedPost.choices[0]?.message?.content
      // Split the text into an array of facts, using newline characters as separators
      const factsArray = rawData.split('\n');
      factsArray.shift();
      const finalArray = factsArray.filter(elm => elm && elm.trim() !== '')
      setResult(finalArray);

    } catch (error) {
      console.error(error);
    }
  };
  
  const handleImgGenerate = async (event) =>{
    {userEmail ? alert('This function is under contruction. Kindly check again later.') :
      alert('This function is available only for registered user. Kindly login to continue.')
    }
  }

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);
    const alertElement = document.querySelector('.alertContainer');
    alertElement.classList.add('copied');

    // Remove the 'copied' class after a certain time (e.g., 2 seconds)
    setTimeout(() => {
      alertElement.classList.remove('copied');
    }, 2000);
    // Optionally, you can show a success message or notification
  };

  const ResultComponent = () => {
    return result? (
      <div>
        {result.map((item, index) => (
            <div className='resultBox'>
            <div className='imgPlace'>
              <p className='imgTxt textAI'>Image</p>
            </div>
            <p className='resultTxt textAI' key={index}>{item}</p>
            <div className='btmBtn'>
              <FaRegCopy className='copyIcon' onClick={() => handleCopy(item)}/>
            </div>
            {language==='EN'?
            <p className='genImgBtn textAI' onClick={handleImgGenerate}>Generate Image</p>
            :
            <p className='genImgBtn textAI' onClick={handleImgGenerate}>Hasilkan Gambar</p>
            }
          </div>
        ))}
      </div>
    ) :(
      <div></div>
    );
  };

  return (
    <div className='sectionsContainer'>
        {language==='EN'?
        <h1 className="aiTitle mt-3">AI Facts</h1>
        :
        <h1 className="aiTitle mt-3">Fakta AI</h1>
        }
        <div className='langContainer'>
          <img src={Malay} className={language==="MY"?"langImg hover-grayscale":"langImg hover-grayscale grayscale"} onClick={() => setLanguage("MY")} alt='malay icon'/>
          <img src={English} className={language==="MY"?"langImg hover-grayscale grayscale":"langImg hover-grayscale"} onClick={() => setLanguage("EN")} alt='english icon'/>
        </div>
        <div className='aiQContainer'>
        {language==='EN'?
        <p className="textAI text-start">Enter a topic:</p>
        :
        <p className="textAI text-start">Tulis Topik:</p>
        }
        <input className="textAI inputAI input-group mb-3" type="text" onChange={handleTopicChange}/>
        {language==='EN'?
        <p className="textAI text-start">Select number of post(Max 10):</p>
        :
        <p className="textAI text-start">Pilih jumlah post(Max 10):</p>
        }
        </div>
        <input className="textAI inputAI input-group mb-3" type="number" max={10} onChange={handlePostNoChange}/>
        {language==='EN'?
        <button className="btn btn-light textAI mb-3" onClick={handleGenerate}>Generate Facts</button>
        :
        <button className="btn btn-light textAI mb-3" onClick={handleGenerate}>Hasilkan Fakta</button>
        }
        <div class="alert alert-info alertContainer" role="alert">
          Copied to clipboard!
        </div>
        {result && <ResultComponent />}
    </div>
  );
}

export default FaktaAi;
