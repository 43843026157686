import React, {useEffect} from 'react'
import sun from '../../static/images/sun.png'
import mercury from '../../static/images/mercury.png'
import venus from '../../static/images/venus.png'
import earth from '../../static/images/earth.png'
import mars from '../../static/images/mars.png'
import asteroid from '../../static/images/asteroid-ring.png'
import jupiter from '../../static/images/jupiter.png'
import saturn from '../../static/images/saturn.png'
import uranus from '../../static/images/uranus.png'
import neptune from '../../static/images/neptune.png'

function SistemSolar() {
  useEffect(() => {
    document.title = "Sistem Solar - science4derp"
  }, []);

  return (
    <div className="sectionsContainer solarContainer" style={{backgroundImage:"linear-gradient(#2a91ab, #050e1a)"}}>
        <div className="container mt-5 py-5">
            <h1 className="bacaanTitle korekTitle" style={{padding:"10px", textAlign: "center"}}>Sistem Solar</h1>
            <p className="bodyText whiteFont text-justify">Sistem Solar merupakan satu kawasan yang terdiri daripada tarikan graviti bintang utama iaitu matahari dan beberapa planet, planet kerdil, bulan dan batuan – batuan. Setiap objek didalam sistem ini bergerak mengikut laluannya tersendiri, dan ada diantaranya yang mengorbit Matahari secara sekata. Jom kenali planet -planet yang ada didalam sistem solar.</p>
            <div className='row align-items-center'>
              <div className='col-sm-7'>
                <img className="img-fluid rotImg" src={sun} alt="sun"/>
                <p className='planetTitle'>MATAHARI</p>
              </div>
              <div className='col-sm-5'>
              <p className='bodyText whiteFont text-justify'>Matahari adalah sebuah bintang dimana wujudnya pembakaran gas hidrogen dan helium yang menyebabkan ianya bersinar dengan cahaya yang terang.</p>
                <p className='bodyText whiteFont text-justify'>Cahaya dari pembakaran yang berlaku di Matahari mengambil masa sehingga 8 minit untuk tiba dibumi.</p>
                <p className='bodyText whiteFont text-justify'>Lebih satu juta bumi mampu dimuatkan didalamnya, walaupun ia dianggap sebagai sebuah bintang bersaiz biasa.</p>
                <p className='bodyText whiteFont text-justify'>Sebagai pusat bagi sistem solar, semua planet mengelilingi matahari, termasuk bumi yang kita diami. Setiap satu pusingan mengelilinginya, satu tahun berlalu.</p>
                <p className='bodyText whiteFont text-justify'>Matahari membesar setiap hari, dan akan tiba masanya nanti planet bumi akan ditelan olehnya.</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-4'>
                <img className="img-fluid" src={mercury} alt="mercury"/>
                <p className='planetTitle'>UTARID</p>
              </div>
              <div className='col-sm-8'>
                <p className='bodyText whiteFont text-justify'>Planet Utarid merupakan planet yang paling hampir dengan Matahari.</p>
                <p className='bodyText whiteFont text-justify'>Walaubagaimanapun, ia bukanlah planet yang paling panas. Ketiadaan atmosfera menyebabkan haba tidak terperangkap pada permukaannya.</p>
                <p className='bodyText whiteFont text-justify'>Ianya juga merupakan planet yang paling kecil dalam sistem solar.</p>
                <p className='bodyText whiteFont text-justify'>Satu tahun di Utarid bersamaan 88 hari di bumi.</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-8 order-sm-1'>
                <p className='bodyText whiteFont text-justify'>Planet Zuhrah adalah planet yang paling hampir dengan bumi, bukannya Marikh.</p>
                <p className='bodyText whiteFont text-justify'>Planet yang paling panas ini bersuhu purata 462°C.</p>
                <p className='bodyText whiteFont text-justify'>Saiznya juga hampir sama dengan bumi, tetapi ianya berpusing amat perlahan pada landasannya. Satu tahun dibumi adalah lebih panjang dari satu hari di Zuhrah.</p>
                <p className='bodyText whiteFont text-justify'>Dikala planet – planet lain berputar mengikut arah lawan jam, hanya Zuhrah dan Uranus yang berputar mengikut arah jam.</p>
                <p className='bodyText whiteFont text-justify'>Saintis percaya planet ini pernah dilanggar oleh Asteroid pada suatu ketika dahulu, meyebabkannya menukar arah pusingannya.</p>
              </div>
              <div className='col-sm-4 order-sm-2'>
                <img className="img-fluid" src={venus} alt="venus"/>
                <p className='planetTitle'>ZUHRAH</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-4'>
                <p className='bodyText whiteFont text-justify'>Planet Bumi yang kita diami ini, merupakan planet ke tiga dari Matahari.</p>
                <p className='bodyText whiteFont text-justify'>Satu- satunya planet yang didiami oleh manusia buat masa ini, dan mungkin buat selamanya.</p>
                <p className='bodyText whiteFont text-justify'>Pusingan Bumi semakin perlahan setiap hari, tetapi akan mengambil masa kira – kira 140 juta tahun sebelum kita akan mempunyai 25 jam sehari.</p>
              </div>
              <div className='col-sm-4 order-sm-1'>
                <img className="img-fluid" src={earth} alt="earth"/>
                <p className='planetTitle'>BUMI</p>
              </div>
              <div className='col-sm-4'>
                <p className='bodyText whiteFont text-justify'>Atmosfera bumi dilapisi dengan lapisan ozon yang melindungi kita semua dari sinar UV dari Matahari.</p>
                <p className='bodyText whiteFont text-justify'>70% dari permukaan bumi kita diliputi oleh lautan dan saliran, dan hanya 30% merupakan daratan.</p>
                <p className='bodyText whiteFont text-justify'>Bulan yang menyinari pada malam hari, sebagai pendamping kepada bumi. Malah, ia juga mempengaruhi pasang surut bagi air di tepian pantai.</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-4'>
                <img className="img-fluid" src={mars} alt="mars"/>
                <p className='planetTitle'>MARIKH</p>
              </div>
              <div className='col-sm-8'>
                <p className='bodyText whiteFont text-justify'>Anda mungkin menyangka hanya bumi yang mempunyai air. Tanggapan ini mungkin salah kerana para pengkaji telah menemui bukti-bukti kewujudan air di Planet Marikh.</p>
                <p className='bodyText whiteFont text-justify'>Planet yang keempat ini mempunyai luas daratan yang hampir sama dengan bumi walaupun saiznya hanya 1/3 dari saiz bumi. Ini kerana bumi diliputi oleh lautan yang besar.</p>
                <p className='bodyText whiteFont text-justify'>Gunung yang tertinggi di dalam sistem solar juga terletak di planet ini, Olympus Mons pada ketinggian 21 km.</p>
                <p className='bodyText whiteFont text-justify'>Selain bumi, planet Marikh mungkin merupakan tempat tinggal yang paling sesuai bagi manusia.</p>
              </div>
            </div>
            <img className="img-fluid" src={asteroid} alt="asteroid" style={{width: "100%"}}/>
            <p className='planetTitle'>GELANG ASTEROID</p>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-4 order-sm-1'>
                <p className='bodyText whiteFont text-justify'>Planet Musytari merupakan planet terbesar didalam sistem solar.</p>
                <p className='bodyText whiteFont text-justify'>1 tahun di Musytari mengambil masa kira – kira hampir 12 tahun dibumi.</p>
                <p className='bodyText whiteFont text-justify'>Planet ini mempunyai 67 buah bulan bagi menemaninya.</p>
                <p className='bodyText whiteFont text-justify'>Lapisan awan pada planet ini sangat unik, membuatkannya kelihatan cantik.</p>
                <p className='bodyText whiteFont text-justify'>Bahagian atas Musytari terbahagi kepada beberapa zon yang terdiri dari kristal ammonia, sulfur dan di permukannya, terdapat lautan terbesar di sistem solar yang dipenuhi hidrogen cecair.</p>
              </div>
              <div className='col-sm-8 order-sm-2'>
                <img className="img-fluid" src={jupiter} alt="jupiter"/>
                <p className='planetTitle'>MUSYTARI</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-8 order-sm-2'>
                <img className="img-fluid" src={saturn} alt="saturn"/>
                <p className='planetTitle'>ZUHAL</p>
              </div>
              <div className='col-sm-4 order-sm-1'>
                <p className='bodyText whiteFont text-justify'>Planet Zuhal mempunyai gelang yang terdiri daripada ais dan debuan karbon.</p>
                <p className='bodyText whiteFont text-justify'>Saiz dan komposisi planet ini adalah hampir sama dengan planet Musytari.</p>
                <p className='bodyText whiteFont text-justify'>Angin di planet ini adalah sangat laju, boleh mencecah 1,800 km/h.</p>
                <p className='bodyText whiteFont text-justify'>Jarak planet keenam dalam sistem solar ini dengan matahari adalah sekitar 1,424,600,000 km.</p>
                <p className='bodyText whiteFont text-justify'>Dan planet Zuhal dikelilingi sehingga 150 bulan.</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-6 order-sm-1'>
                <p className='bodyText whiteFont text-justify'>Planet Uranus mula ditemui pada tahun 1781, merupakan planet yang pertama dijumpai manusia.</p>
                <p className='bodyText whiteFont text-justify'>Kedudukannya yang sedikit senget mungkin disebabkan oleh perlanggaran dengan asteroid pada suatu ketika.</p>
                <p className='bodyText whiteFont text-justify'>Ia juga dikenali sebagai ‘Ice Giant’ dimana permukaannya diselaputi oleh kristal ais dari ammonia, air dan metana.</p>
                <p className='bodyText whiteFont text-justify'>Planet ini mempunyai 13 gelang yang mengelilinginya, dan saintis percaya merupakan tinggalan cebisan bulan-bulan yang telah dilanggar.</p>
                <p className='bodyText whiteFont text-justify'>Planet ini juga adalah planet yang paling sejuk dengan suhu minima yang mencecah -224°C.</p>
              </div>
              <div className='col-sm-6 order-sm-2'>
                <img className="img-fluid" src={uranus} alt="jupiter"/>
                <p className='planetTitle'>URANUS</p>
              </div>
            </div>
            <div className='row align-items-center mt-5'>
              <div className='col-sm-6'>
                <img className="img-fluid" src={neptune} alt="saturn"/>
                <p className='planetTitle'>NEPTUN</p>
              </div>
              <div className='col-sm-6'>
                <p className='bodyText whiteFont text-justify'>Satu tahun di Planet Neptun mengambil masa kira -kira 165 tahun dibumi</p>
                <p className='bodyText whiteFont text-justify'>Planet yang paling jauh dari matahari ini mempunyai graviti yang cukup kuat, dan menyebabkan ribut sebesar saiz bumi pada permukaannya.</p>
                <p className='bodyText whiteFont text-justify'>Buat masa ini, hanya ada sebuah kapal angkasa yang telah terbang melepasi planet dihujung  sistem solar ini, iaitu ‘Voyager II’.</p>
                <p className='bodyText whiteFont text-justify'>Planet ini juga sebenarnya mempunyai gelangnya yang tersendiri, cuma ia kurang kelihatan.</p>
              </div>
            </div>
            <div className='mt-5'>
              <p className='bodyText whiteFont text-justify'>Planet adalah objek yang mengelilingi matahari pada orbitnya(1), mempunyai jisim yang bulat atau sfera(2), dan tidak mempunyai bendasing pada orbitnya(3).</p>
              <p className='bodyText whiteFont text-justify'>Beberapa tahun yang lalu, Planet Pluto juga termasuk dalam senarai planet, akan tetapi ianya kini digelar sebagai Planet 'Kerdil' disamping 4 planet yang lain iaitu Ceres, Haumea, Makemake dan Eris.</p>
            </div>
            
        </div>
    </div>
  )
}

export default SistemSolar